import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Sidebar } from '../../components/sections/Sidebar'
import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native'
import { TopBar } from '../../components/sections/TopBar'
import CreateTaskBar from '../../components/common/CreateTaskBar/CreateTaskBar'
import TasksDataTable from '../../components/common/TaskDataTable/TasksDataTable'
import { useTaskProvider } from '../../context/TaskProvider'
import { getStyleSheet } from '../../assets/theme/styles'
import { useUserProvider } from '../../context/UserProvider'
import { useProjectProvider } from '../../context/ProjectProvider'
import { TagsService } from '../../services/TagsService'
import { ThemeContext } from '../../assets/theme/ThemeProvider'
import { IUserOrganization } from '../../interfaces/user-organization.interface'
import moment from 'moment'
import { secondsToString } from '../../helpers/utils'
import SkeletonTable from '../../components/common/SkeletonTable'
import { useFocusEffect } from '@react-navigation/native'
import FooterDialog from '../../components/common/FooterDialog'
import CustomModal from '../../components/common/CustomModal'
import ItemSkeleton from '../../components/common/ItemSkeleton'
import useDimensions from '../../hooks/useDimensions'
import { useOverlayProvider } from '../../context/OverlayProvider'

const steepsInrodutionTimerz = {
    stepsEnabled: false,
    initialStep: 0,
    steps: [
        {
            title: 'Welcome',
            intro: 'Welcome, this is an introduction to Timerz',
        },
        {
            element: '.top-bar-tz .top-bar',
            intro: 'Here you will find information about the organization in which you are working',
        },
        {
            element: '.p-autocomplete',
            intro: 'Start a task',
        },
    ],
}

const stepsCreateTask = {
    stepsEnabled: false,
    initialStep: 0,
    steps: [
        {
            element: '.p-autocomplete',
            intro: 'Add description task',
        },
        {
            element: '.p-input-icon-right',
            intro: 'Select project (optional)',
        },
        {
            element: '.create-task-tz-tags-container',
            intro: 'Select tags (optional)',
        },
        {
            element: '.button-tz',
            intro: 'Press start',
            position: 'left',
        },
    ],
}

const Timer = () => {
    const [currentDelete, setCurrentDelete] = useState<any>(null)
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
    const [steeps, setSteeps] = useState(steepsInrodutionTimerz)
    const [steepsCreateTask, setSteepsCreateTask] = useState(stepsCreateTask)
    const [isNewTask, setIsNewTask] = useState(false)
    const { localUserData, userActiveOrganization, timeFormat, dbUserData, updateUserById } = useUserProvider()
    const { getAllTaskByUser, localTasks, loading } = useTaskProvider()
    const { getProjectsDropDown } = useProjectProvider()
    const [allProjects, setAllProjects] = useState<any>([])
    const [tags, setTags] = useState<any>(null)
    const { onDeleteRow } = useTaskProvider()
    const { pomodoroTime } = useContext<any>(ThemeContext)
    const [initialWeek, setInitialWeek] = useState(moment().startOf('week'))
    const endDate = moment().add(1, 'day')
    const [totalTime, setTotalTime] = useState(0)
    const [dateRange, setDateRange] = useState('')
    const current = 'Timer'
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()
    const { closeShowOverlay } = useOverlayProvider()

    const loadGroupedProjects = async (userSub: string, organization: IUserOrganization) => {
        const projects = await getProjectsDropDown(userSub, organization)
        setAllProjects(projects)
    }

    const loadMoreTasks = async () => {
        const newInitialWeek = moment(initialWeek).subtract(1, 'week')
        setInitialWeek(newInitialWeek)

        const datesRange = [newInitialWeek, initialWeek]

        if (localUserData && userActiveOrganization) {
            await getAllTaskByUser(localUserData.sub, userActiveOrganization.organizationID, datesRange)
        }
    }

    const getAllTags = (userSub: string) => {
        TagsService()
            .getAllTags(userSub)
            .then(data => {
                setTags(data)
            })
    }

    const handleGetData = () => {
        if (localUserData?.sub && userActiveOrganization && timeFormat && pomodoroTime > 0) {
            const datesRange = [initialWeek, endDate]
            getAllTaskByUser(localUserData.sub, userActiveOrganization.organizationID, datesRange, true)
            loadGroupedProjects(localUserData.sub, userActiveOrganization)
            getAllTags(localUserData.sub)
        }
    }

    useFocusEffect(useCallback(handleGetData, [localUserData, userActiveOrganization, timeFormat, pomodoroTime]))

    useEffect(() => {
        if (!localTasks.length) return

        const startWeek = moment().startOf('week').valueOf()

        const totalTimeTmp: number = localTasks.reduce((acc: number, task: any) => {
            acc += Number(task.totalTime)
            return acc
        }, 0)

        const lastTask = moment(localTasks?.at(-1)?.Id)

        if (lastTask.valueOf() < startWeek) {
            setDateRange(`${lastTask.format('ll')} - ${moment().format('ll')}`)
        } else {
            setDateRange('Week')
        }

        setTotalTime(totalTimeTmp)
    }, [localTasks])

    const onUpdateMultipleTask = async () => {
        const datesRange = [initialWeek, endDate]

        if (localUserData && userActiveOrganization) {
            await getAllTaskByUser(localUserData.sub, userActiveOrganization.organizationID, datesRange, true)
        }
    }

    const onHideConfirmationDelete = () => {
        setShowConfirmationDelete(false)
    }

    const onShowConfirmationDelete = (id: any) => {
        setCurrentDelete(id)
        setShowConfirmationDelete(true)
    }

    const disableStepHowToAdd = () => {
        setSteeps({
            ...steeps,
            stepsEnabled: false,
        })
        //@ts-ignore
        navigation.navigate('Profile', { isRedirectToTimer: true } as never)
    }

    const disableStepHowToAddTasks = () => {
        setSteepsCreateTask({
            ...steepsCreateTask,
            stepsEnabled: false,
        })
    }

    const enableStepHowToAddTasks = () => {
        setSteepsCreateTask({
            ...steepsCreateTask,
            stepsEnabled: true,
        })
    }

    useEffect(() => {
        if (dbUserData?.newUser === true) {
            setSteeps({
                ...steeps,
                stepsEnabled: true,
            })

            const paramsUser: any = {
                id: dbUserData?.id,
                newUser: false,
                _version: dbUserData?._version,
            }

            updateUserById(paramsUser)
        }
    }, [dbUserData])

    console.log({ loading, localTasks })

    return (
        <TouchableWithoutFeedback onPress={closeShowOverlay}>
            <View style={[localStyles.container]}>
                <Sidebar children={{ active: current }} />

                <View style={[localStyles.subcontainer, styles.styleBackground.container]}>
                    <TopBar children={{ active: current }} />

                    <CreateTaskBar datesRange={[initialWeek, endDate]} onNewTask={setIsNewTask} />

                    {loading && !localTasks?.length ? (
                        <View style={{ marginHorizontal: 40 }}>
                            <SkeletonTable rowsQuantity={8} />
                        </View>
                    ) : (
                        <View style={{ flexGrow: 1, marginHorizontal: windowWidth > 1200 ? 40 : 20, marginTop: 20 }}>
                            <View style={localStyles.tableHeader}>
                                {loading ? (
                                    <ItemSkeleton height={30} width={100} />
                                ) : (
                                    <Text style={{ fontSize: 16, color: styles.text }}>{dateRange}</Text>
                                )}

                                {loading ? (
                                    <ItemSkeleton height={30} width={100} />
                                ) : (
                                    <Text style={{ fontSize: 20, fontWeight: '600', color: styles.text }}>
                                        {`Total: ${secondsToString(totalTime)}`}
                                    </Text>
                                )}
                            </View>

                            <TasksDataTable
                                key="data-table"
                                loadMoreTasks={loadMoreTasks}
                                projects={allProjects}
                                allTasks={localTasks}
                                tags={tags}
                                loadingTasks={loading}
                                timeFormat={timeFormat}
                                userActiveOrganization={userActiveOrganization}
                                onShowConfirmationDelete={onShowConfirmationDelete}
                                enableStepHowToAddTasks={enableStepHowToAddTasks}
                                onUpdateMultipleTask={onUpdateMultipleTask}
                            />
                        </View>
                    )}
                </View>

                <CustomModal
                    message="Are you sure you want to remove task?"
                    header="Delete"
                    isVisible={showConfirmationDelete}
                    onHide={setShowConfirmationDelete}
                    footerModal={
                        <FooterDialog
                            handleOnNo={onHideConfirmationDelete}
                            handleOnYes={() => {
                                setShowConfirmationDelete(false)
                                onDeleteRow(currentDelete)
                            }}
                        />
                    }
                />
            </View>
        </TouchableWithoutFeedback>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'row',
        position: 'relative',
    },
    subcontainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
    },
    containerIconList: {
        flexDirection: 'row',
        minHeight: 30,
        overflow: 'hidden',
        width: '100%',
        alignItems: 'center',
        marginTop: 0,
        marginBottom: 15,
    },
    buttonYesNoContainer: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderRadius: 4,
        width: 100,
    },
    buttonYesNoTitle: {
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        lineHeight: 19,
        letterSpacing: 0.25,
    },
    buttonYesNo: {
        backgroundColor: 'transparent',
        height: 58,
    },
    tableHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderColor: '#969696',
        paddingBottom: 15,
    },
})

export default Timer
