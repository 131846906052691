import { useControlEditionTasks } from '../../../hooks/useControlEditionTasks'
import { Platform, ScrollView, Text, View } from 'react-native'
import { useState } from 'react'
import TaskListHeader from './TaskListHeader'
import TaskRow from './TaskRow'
import ButtonNative from '../ButtonNative'
import { getStyleSheet } from '../../../assets/theme/styles'
import { FORMAT } from '../../../interfaces/user-provider.interface'
import { IUserOrganization } from '../../../interfaces/user-organization.interface'
import useDimensions from '../../../hooks/useDimensions'

interface ITaskDataTable {
    allTasks: any[]
    projects: any[]
    tags: any[]
    loadingTasks: boolean
    timeFormat: FORMAT
    userActiveOrganization: IUserOrganization | undefined
    loadMoreTasks: () => void
    onShowConfirmationDelete: (id: string) => void
    enableStepHowToAddTasks: () => void
    onUpdateMultipleTask: () => Promise<void>
}

const TasksDataTable = ({
    allTasks,
    projects,
    tags,
    timeFormat,
    loadingTasks,
    userActiveOrganization,
    loadMoreTasks,
    onShowConfirmationDelete,
    enableStepHowToAddTasks,
    onUpdateMultipleTask,
}: ITaskDataTable) => {
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()
    const showsHorizontalScrollIndicator = Platform.OS !== 'web'

    return (
        <>
            <ScrollView
                showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
                style={{ marginTop: 30, flexGrow: 1, height: 0 }}
            >
                <View nativeID="listRow" style={{ gap: 30, zIndex: 100 }}>
                    {allTasks.map(({ tasks, Id, totalTime, index }) => {
                        return (
                            <View
                                nativeID="taskRow"
                                key={Id}
                                style={[{ gap: 10, zIndex: index }, windowWidth >= 1000 ? { gap: 12 } : null]}
                            >
                                <TaskListHeader
                                    key={`h${Id}`}
                                    loadingTasks={loadingTasks}
                                    taskDate={Id}
                                    totalTime={totalTime}
                                />

                                <TasksRender
                                    loadingTasks={loadingTasks}
                                    onShowConfirmationDelete={onShowConfirmationDelete}
                                    onUpdateMultipleTask={onUpdateMultipleTask}
                                    projects={projects}
                                    tags={tags}
                                    tasks={tasks}
                                    timeFormat={timeFormat}
                                    userActiveOrganization={userActiveOrganization}
                                />

                                <View
                                    style={{
                                        backgroundColor: '#969696',
                                        height: 1,
                                        transform: [{ translateY: 13 }],
                                    }}
                                />
                            </View>
                        )
                    })}
                </View>

                {allTasks.length === 0 && !loadingTasks ? (
                    <View
                        style={[
                            { alignItems: 'center', justifyContent: 'center', gap: 4 },
                            windowWidth >= 500 ? { flexDirection: 'row' } : null,
                        ]}
                    >
                        <Text style={{ color: styles.text }}>Go ahead and enter your first task of today!.</Text>
                        {/* <Text
                            style={{ color: '#3B82f6', fontWeight: '500', textAlign: 'right' }}
                            onPress={() => enableStepHowToAddTasks()}
                        >
                            How to add?
                        </Text> */}
                    </View>
                ) : null}

                <View style={{ alignItems: 'center' }}>
                    <ButtonNative
                        title="Load more..."
                        containerStyle={{
                            borderWidth: 1,
                            borderColor: '#64748ba9',
                            width: 120,
                            marginTop: 40,
                            marginBottom: 10,
                            marginHorizontal: 'auto',
                        }}
                        buttonStyle={{ backgroundColor: styles.buttonLoadMore }}
                        titleStyle={{ color: styles.text }}
                        onPress={loadMoreTasks}
                    />
                </View>
            </ScrollView>
        </>
    )
}

const TasksRender = ({
    tasks,
    projects,
    tags,
    onShowConfirmationDelete,
    loadingTasks,
    onUpdateMultipleTask,
    userActiveOrganization,
    timeFormat,
}) => {
    const [rowsToSpand, setRowsToSpand] = useState<string[]>([])
    const [rowWidth, setRowWidth] = useState(0)

    return tasks.map((task: any) => {
        const disableEdition = useControlEditionTasks().validationEditionTimeFrame(task.created)

        return (
            <View onLayout={({ nativeEvent: { layout } }) => setRowWidth(layout.width)}>
                <TaskRow
                    key={task.id}
                    task={task}
                    idSendToExpand={task.staticId}
                    disableEdition={disableEdition}
                    projects={projects}
                    tags={tags}
                    rowsToSpand={rowsToSpand}
                    setRowsToSpand={setRowsToSpand}
                    timeFormat={timeFormat}
                    isParentTask={task?.groupTask?.length > 1}
                    onShowConfirmationDelete={onShowConfirmationDelete}
                    loadingTasks={loadingTasks}
                    onUpdateMultipleTask={onUpdateMultipleTask}
                    userActiveOrganization={userActiveOrganization}
                    rowWidth={rowWidth}
                />

                {task?.groupTask?.length > 1 ? (
                    <View
                        style={[
                            { display: 'none', marginTop: 7, marginBottom: 10, alignItems: 'flex-end', gap: 5 },
                            rowsToSpand.includes(task.staticId) ? { display: 'flex' } : null,
                        ]}
                    >
                        {task.groupTask.map((insideTask: any) => (
                            <TaskRow
                                key={insideTask.id}
                                task={insideTask}
                                disableEdition={disableEdition}
                                projects={projects}
                                tags={tags}
                                rowsToSpand={rowsToSpand}
                                timeFormat={timeFormat}
                                setRowsToSpand={setRowsToSpand}
                                isInsideTask
                                onShowConfirmationDelete={onShowConfirmationDelete}
                                loadingTasks={loadingTasks}
                                onUpdateMultipleTask={onUpdateMultipleTask}
                                userActiveOrganization={userActiveOrganization}
                                rowWidth={rowWidth}
                            />
                        ))}
                    </View>
                ) : null}
            </View>
        )
    })
}

export default TasksDataTable
