import React, { useCallback, useContext } from 'react'
import { View, ScrollView, Text, StyleSheet, Pressable, Linking, Image, ActivityIndicator } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import DataTable, { MomentaryView } from '../components/common/DataTable'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { OrganizationService } from '../services/OrganizationService'
import moment from 'moment'
import MultiselectMembers from '../components/common/MultiSelectMembers/MultiselectMembers'
import ReportService from '../services/ReportService'
import MultiselectProjects from '../components/common/MultiselectProjects/MultiselectProjects'
import RowReport from '../components/common/RowReport'
import uuid from 'react-native-uuid'
import useDimensions from '../hooks/useDimensions'
import ButtonNative from '../components/common/ButtonNative'
import DatePicker from '../components/common/DatePicker/DatePicker'
import { CSVLink } from 'react-csv'
import Chart from '../components/common/Chart'
import * as Progress from 'react-native-progress'
import DropdownNative from '../components/common/MultiSelectNative/MultiSelect'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { useOverlayProvider } from '../context/OverlayProvider'
import { Platform } from 'react-native'
import { monthsOfYear } from '../components/common/DatePicker/useDatePicker'
import useState from 'react-usestateref'

interface IParamsObject {
    projects?: any
    members?: any
    beginDate: string
    finishDate: string
    dateRange?: string
}

const headerColumns = [
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 40 } },
    { label: 'TITLE', fieldToSorteable: 'name', isSorteable: true, styles: { flexGrow: 1 } },
    { label: 'TASKS', fieldToSorteable: '', isSorteable: false, styles: { width: 50 } },
    { label: 'DURATION', fieldToSorteable: '', isSorteable: false, styles: { width: 60 } },
]

export const OPTIONS_DATES_RANGE = [
    {
        label: 'Today',
        dates: [new Date(moment().valueOf()), new Date(moment().valueOf())],
    },
    {
        label: 'Yesterday',
        dates: [new Date(moment().add(-1, 'day').valueOf()), new Date(moment().add(-1, 'day').valueOf())],
    },
    {
        label: 'Current week',
        dates: [new Date(moment().startOf('week').valueOf()), new Date(moment(moment().endOf('week')).valueOf())],
    },
    {
        label: 'Last 2 weeks',
        dates: [new Date(moment().subtract(2, 'weeks').valueOf()), new Date(moment().valueOf())],
    },
    {
        label: 'Current Month',
        dates: [new Date(moment().startOf('month').valueOf()), new Date(moment().endOf('month').valueOf())],
    },
    {
        label: 'Last Month',
        dates: [
            new Date(moment().subtract(1, 'months').startOf('month').valueOf()),
            new Date(moment().subtract(1, 'months').endOf('month').valueOf()),
        ],
    },
]

export type DATE_RANGE = { label: string; dates: Date[] } | null
const groupDropdownId = uuid.v4() as string
const exportTypeDropdownId = uuid.v4() as string
const reportId = uuid.v4() as string

const Reports = ({ navigation }) => {
    const current = 'Reports'
    const styles = getStyleSheet()
    const initDate = new Date(moment().startOf('week').valueOf())
    const endDate = new Date(moment().endOf('week').valueOf())
    const progressCircleInit = ReportService().progressCircleInit()
    const { userSub, pomodoroTime } = useContext<any>(ThemeContext)
    const [loading, setLoading] = useState(false)
    const [filterByProject, setFilterByProject] = useState([])
    const [filterByMember, setFilterByMember] = useState([])
    const [projectDataTable, setProjectDataTable] = useState<any>()
    const [_datesRange, setDatesRange, datesRangeRef] = useState<any[]>([])
    const [_arrayTasks, setArrayTasks, arrayTasksRef] = useState<any>()
    const [selectedProjects, setSelectedProjects] = useState([])
    const [selectedMembers, setSelectedMembers] = useState([])
    const [progressCircle, setProgressCircle] = useState<any>(progressCircleInit)
    const [dateRange, setDateRange] = useState<DATE_RANGE>(OPTIONS_DATES_RANGE[2])
    const [dataChart, setDataCharts] = useState<any>([])
    const [exportData, setExportData] = useState<any>([])
    const [workSpaceName, setWorkSpaceName] = useState('')
    const [loadingPdf, setLoadingPdf] = useState(false)
    const { windowWidth } = useDimensions()
    const [chartContainerWidth, setChartContainerWidth] = useState(0)
    const { closeShowOverlay } = useOverlayProvider()
    const groupByItems = ReportService().groupByItems()
    const [groupedSelect, setGroupedSelect] = useState(groupByItems[0])
    const csvReport = ReportService().exportSummaryCsv(exportData)
    const route = useRoute()

    const exportPdf = () => {
        setLoadingPdf(true)
        ReportService()
            .exportSummaryPdf(
                exportData,
                datesRangeRef.current.map(date => moment(date).format('YYYY/MM/DD')),
                progressCircle.progressFormat,
                workSpaceName
            )
            .then(() => setLoadingPdf(false))
    }

    const applyFiltersReport = (projects?: any, members?: any, dateRangeOption?: string) => {
        const beginDate = moment(datesRangeRef.current[0]).format('YYYY-MM-DD')
        const finishDate = moment(datesRangeRef.current[1]).format('YYYY-MM-DD')

        const datesRangeFilter = [
            moment(datesRangeRef.current[0]).startOf('day'),
            moment(datesRangeRef.current[1]).endOf('day'),
        ]

        const filterToMembers = members ?? filterByMember
        const filterToProjects = projects ?? filterByProject
        const dateRangeLabel = dateRangeOption ?? dateRange?.label

        const paramsObj: IParamsObject = {
            beginDate,
            finishDate,
            projects: filterToProjects?.toString(),
            members: filterToMembers?.toString(),
            dateRange: dateRangeLabel,
        }

        navigation.setParams(paramsObj)

        if (userSub) {
            setLoading(true)
            ReportService()
                .getTasksByRangeDate(datesRangeFilter, userSub)
                .then(response => {
                    setArrayTasks(response)
                    applyFilters(filterToProjects, filterToMembers)
                    setLoading(false)
                })
                .catch(error => {
                    console.log({ error })
                    setLoading(false)
                })
        }
    }

    const handleDateRange = (value: any) => {
        setDatesRange(value)
    }

    function handleFilterProject(values) {
        setFilterByProject(values)
        setSelectedProjects(values.map(project => ({ value: project })))
    }

    const handleFilterMember = values => {
        setFilterByMember(values)
        setSelectedMembers(values.map(member => ({ code: member })))
    }

    const applyFilters = (filterProjects: any[], filterMembers: any[]) => {
        const filterList: any[] = []

        filterProjects.forEach(project => {
            const find = arrayTasksRef.current.filter(item => item.projectID === project)
            filterList.push(find)
        })

        const newArray: any[] = filterProjects.length ? filterList : arrayTasksRef.current

        filterMembers.forEach(member => {
            const find = newArray.filter(item => item.userSub === member)
            filterList.push(find)
        })

        if (filterProjects.length || filterMembers.length) {
            getDataChart(filterList.flat())
        } else {
            getDataChart(arrayTasksRef.current)
        }
    }

    const getDataChart = (tasks: Array<any>) => {
        const startDate = new Date(moment(datesRangeRef.current[0]).valueOf())
        const endDate = new Date(moment(datesRangeRef.current[1]).valueOf())
        const groupedDataTable = ReportService().getGroupedDatatable(tasks, groupedSelect.code)
        setProjectDataTable(groupedDataTable.map(item => ({ ...item, id: item.id ?? uuid.v4() })))

        // The function "transformDataToExport" needs the fields: tasks, durationFormat, createdDateFormat and tasks no grouped
        const groupedTaskForReport = groupedDataTable
            .map(item => {
                if (item.tasks.length)
                    return item.tasks.map(subTask => ({
                        ...subTask,
                        tasks: [],
                        date: subTask.createdDateFormat,
                        durationFormat: subTask.time,
                        createdDateFormat: moment(subTask.createdDateFormat, 'YYYY MM DD hh:mm:ss Z').format(
                            'MMM DD, YYYY'
                        ),
                    }))
                return item
            })
            .flat()
        const dataExportFile = ReportService().transformDataToExport(groupedTaskForReport)

        setExportData(dataExportFile)
        const dataProgressBar = ReportService().getDataProgressBar(groupedDataTable)
        setProgressCircle(dataProgressBar)

        ReportService()
            .getDataChart(tasks, startDate, endDate, pomodoroTime)
            .then(response => {
                setDataCharts(response)
            })
            .catch(error => console.log('ERROR', error))
    }

    const onChangeGroupItem = (value: string) => {
        const findOption = groupByItems?.find(({ code }) => code === value)
        if (findOption) setGroupedSelect(findOption)
    }

    const HeaderDataTable = () => (
        <>
            <View style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <Text style={{ lineHeight: 35, marginRight: 5, fontSize: 14, color: styles.text }}>Group by</Text>
                <DropdownNative
                    valueWhenUniqueSelection={groupedSelect.code}
                    options={groupByItems}
                    uniqueSelection
                    indentificator={groupDropdownId}
                    onChange={onChangeGroupItem}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Group by"
                    hideSearch
                    customStyles={windowWidth < 600 ? { paddingHorizontal: 10, paddingVertical: 0 } : null}
                    containerStyles={{ flexGrow: 0 }}
                    overlayPanelStyles={{ minWidth: 150 }}
                />
            </View>

            <View
                style={{
                    height: 1,
                    marginTop: 16,
                    backgroundColor: styles.separatorBorder,
                    width: '100%',
                }}
            />
        </>
    )

    const getDefaultOrganization = () => {
        OrganizationService()
            .getDefaultOrganizationName(userSub)
            .then(result => {
                setWorkSpaceName(result?.organization?.name)
            })
    }

    useFocusEffect(
        useCallback(() => {
            const params = route.params as any
            const members = params?.members ? params?.members?.split(',') : null
            const projects = params?.projects ? params?.projects?.split(',') : null

            if (params?.projects) {
                setFilterByProject(projects)
                setSelectedProjects(projects.map(project => ({ value: project })))
            }

            if (params?.members) {
                setFilterByMember(members)
                setSelectedMembers(members.map(member => ({ code: member })))
            }

            const { datesNow, dateRangeLabel } = getInitialDateRange()

            if (userSub) {
                handleDateRange(datesNow)
                setDateRange({ dates: datesNow, label: String(dateRangeLabel) })
                applyFiltersReport(projects, members, dateRangeLabel)
                getDefaultOrganization()
            }
        }, [userSub])
    )

    const getInitialDateRange = () => {
        const params = route.params as any

        const datesNow =
            params?.beginDate && params?.finishDate
                ? [new Date(moment(params.beginDate).valueOf()), new Date(moment(params.finishDate).valueOf())]
                : [initDate, endDate]

        const label = `${monthsOfYear[Number(datesNow[0]?.getMonth())].text} ${datesNow[0]?.getDate()} - ${
            monthsOfYear[Number(datesNow[1]?.getMonth())].text
        } ${datesNow[1]?.getDate()}`

        const dateRangeLabel = params?.dateRange ?? label

        return { datesNow, dateRangeLabel }
    }

    const ExportsList = () => {
        return (
            <>
                <Pressable
                    onPress={() => {
                        closeShowOverlay()
                        exportPdf()
                    }}
                    style={{ padding: 6 }}
                >
                    <Text style={{ color: styles.text }}>PDF</Text>
                </Pressable>
                {Platform.OS === 'web' ? (
                    <CSVLink style={{ padding: 6, textDecoration: 'none' }} {...csvReport}>
                        <Pressable onPress={closeShowOverlay}>
                            <Text style={{ color: styles.text }}>CSV</Text>
                        </Pressable>
                    </CSVLink>
                ) : null}
            </>
        )
    }

    return (
        <View style={{ flex: 1, flexDirection: 'row', zIndex: 9 }}>
            <Sidebar children={{ active: current }} />
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={[styles.styleBackground.container, { paddingBottom: 20 }]}
                stickyHeaderIndices={[0]}
            >
                <TopBar children={{ active: current, initial: 0 }} />
                <View style={[{ marginHorizontal: windowWidth < 375 ? 15 : 30, marginTop: 14 }]}>
                    <View
                        style={[
                            { gap: 10 },
                            windowWidth > 1000 ? { flexDirection: 'row', justifyContent: 'space-between' } : null,
                        ]}
                    >
                        <View style={{ flexDirection: 'row', gap: 10 }}>
                            <DatePicker
                                identificator={reportId}
                                isSelectedByRange
                                multipleValues={dateRange?.dates}
                                buttonLabel={dateRange?.label}
                                setDateRange={setDateRange}
                                onChangeMultiple={handleDateRange}
                            >
                                <RenderOptionsDatePicker dateRange={dateRange} setDateRange={setDateRange} />
                            </DatePicker>

                            <MultiselectProjects
                                buttomStyle={{ borderWidth: 1, borderColor: styles.dropdownBorderColor }}
                                selectedProject={selectedProjects}
                                onProjectChange={handleFilterProject}
                            />

                            {windowWidth > 1000 ? (
                                <MultiselectMembers
                                    selectedMember={selectedMembers}
                                    buttomStyle={{ borderWidth: 1, borderColor: styles.dropdownBorderColor }}
                                    onMemberChange={handleFilterMember}
                                    filtering={true}
                                />
                            ) : null}
                        </View>

                        <MomentaryView customStyles={{ flexDirection: 'row', gap: 6 }} showViewUp={1000}>
                            {windowWidth <= 1000 ? (
                                <MultiselectMembers
                                    buttomStyle={{ borderWidth: 1, borderColor: styles.dropdownBorderColor }}
                                    onMemberChange={handleFilterMember}
                                    filtering={true}
                                />
                            ) : null}

                            <View style={{ flexDirection: 'row', gap: windowWidth <= 1000 ? 6 : 10 }}>
                                <DropdownNative
                                    valueWhenUniqueSelection=""
                                    uniqueSelection
                                    indentificator={exportTypeDropdownId}
                                    onChange={() => {}}
                                    options={[]}
                                    optionLabel=""
                                    optionValue=""
                                    placeholder="Export"
                                    hideSearch
                                    valueTemplate={
                                        <View style={{ flexDirection: 'row', gap: 10 }}>
                                            <Text style={{ color: styles.text }}>Export</Text>
                                            {loadingPdf ? <ActivityIndicator color={styles.text} /> : null}
                                        </View>
                                    }
                                    childrenOverlay={<ExportsList />}
                                    overlayPanelStyles={{ minWidth: 110 }}
                                    disabled={loadingPdf}
                                    customStyles={{
                                        paddingHorizontal: 10,
                                        paddingVertical: 4,
                                        borderWidth: 1,
                                        borderColor: styles.dropdownBorderColor,
                                    }}
                                />

                                <ButtonNative
                                    style={{ flexGrow: 1 }}
                                    containerStyle={{ flexGrow: 1 }}
                                    buttonStyle={{ flexGrow: 1 }}
                                    titleStyle={{ color: '#fff' }}
                                    primaryTheme
                                    title="Filter"
                                    onPress={() => applyFiltersReport()}
                                    loading={loading}
                                />
                            </View>
                        </MomentaryView>
                    </View>

                    <View
                        style={{
                            marginTop: 30,
                            marginBottom: 20,
                            backgroundColor: '#FFFFFF',
                            borderRadius: 10,
                            overflow: 'hidden',
                        }}
                        onLayout={e => setChartContainerWidth(e.nativeEvent.layout.width)}
                    >
                        <View style={{ padding: 10 }}>
                            <Chart
                                chartContainerWidth={chartContainerWidth}
                                data={dataChart}
                                axisX="axisX"
                                axisY="axisY"
                            />
                            <View style={{ alignItems: windowWidth <= 800 ? 'center' : 'flex-end', marginTop: 20 }}>
                                <View
                                    style={{
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        gap: 10,
                                        paddingVertical: 10,
                                        paddingHorizontal: 20,
                                        width: windowWidth <= 800 ? '100%' : 'auto',
                                    }}
                                >
                                    <View style={{ flexDirection: 'row', gap: 16 }}>
                                        <Image
                                            resizeMode="contain"
                                            style={{ width: 35, height: 40 }}
                                            source={require('../assets/images/green.png')}
                                        />
                                        <Image
                                            resizeMode="contain"
                                            style={{ width: 35, height: 40 }}
                                            source={require('../assets/images/orange.png')}
                                        />
                                        <Image
                                            resizeMode="contain"
                                            style={{ width: 35, height: 40 }}
                                            source={require('../assets/images/red.png')}
                                        />
                                    </View>
                                    <ButtonNative
                                        title="Read more"
                                        containerStyle={{ width: windowWidth <= 800 ? '100%' : 'auto' }}
                                        buttonStyle={{ backgroundColor: '#e8edf1' }}
                                        titleStyle={{ color: 'gray' }}
                                        onPress={() => Linking.openURL('https://timerz.io/blog/how-to-use-pomodoro/')}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[{ columnGap: 20 }, windowWidth > 1000 ? { flexDirection: 'row' } : null]}>
                        <View style={[{ flexGrow: 1 }, windowWidth <= 1000 ? { marginBottom: 20 } : null]}>
                            {HeaderDataTable()}

                            <DataTable
                                itemsList={projectDataTable}
                                setItemsList={setProjectDataTable}
                                headerColumns={headerColumns}
                                customStyles={{ marginTop: 10, gap: 10 }}
                                headerCustomStyles={{ paddingVertical: 14, paddingHorizontal: 20, gap: 20 }}
                                emptyMessage="No tasks"
                                isShowEmptyMessage={projectDataTable?.length === 0}
                            >
                                {projectDataTable?.map((project: any) => (
                                    <DetailRowContentRender project={project} />
                                ))}
                            </DataTable>
                        </View>

                        <View
                            style={[
                                localStyles.containerProgressBar,
                                styles.styleRow.container,
                                windowWidth > 800 ? { width: 232, marginLeft: 'auto', alignItems: 'stretch' } : null,
                            ]}
                        >
                            <View style={localStyles.subcontainerProgressBar}>
                                <Progress.Circle
                                    color="#508BED"
                                    borderWidth={2}
                                    progress={loading ? 0 : 1}
                                    size={130}
                                    animated
                                />
                                <Text style={[localStyles.progressBarText, styles.styleRow.colorText]}>
                                    {progressCircle.progressFormat + ' / ' + progressCircle.totalFormat}
                                </Text>
                            </View>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginLeft: 10,
                                    marginTop: 10,
                                }}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={[
                                            { marginTop: 8, marginLeft: 15, color: '#ffffff' },
                                            styles.styleRow.colorText,
                                        ]}
                                    >
                                        {progressCircle.pomodoros}
                                    </Text>
                                    <Image
                                        source={require('../assets/icons/pomodoro.png')}
                                        style={{ width: 23, height: 23, marginLeft: 10, marginTop: 5 }}
                                    />
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        marginRight: 20,
                                        marginLeft: windowWidth <= 800 ? 40 : 'auto',
                                    }}
                                >
                                    <Image
                                        source={require('../assets/icons/emoji-sleep.png')}
                                        style={{ width: 23, height: 23, marginLeft: 10, marginTop: 5 }}
                                    />
                                    <Text
                                        style={[
                                            { marginTop: 8, marginLeft: 5, color: '#ffffff' },
                                            styles.styleRow.colorText,
                                        ]}
                                    >
                                        {progressCircle.sleepFormat}
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={
                                    !Number.isNaN(progressCircle.pomodorosEditedPercent)
                                        ? {
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              marginLeft: 10,
                                              marginTop: 10,
                                          }
                                        : { display: 'none' }
                                }
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={[
                                            {
                                                marginTop: 8,
                                                marginLeft: 15,
                                                color: '#ffffff',
                                                fontWeight: '500',
                                            },
                                            styles.styleRow.colorText,
                                        ]}
                                    >
                                        Edited pomodoro cycles {progressCircle.pomodorosEditedPercent.toFixed()}%
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const DetailRowContentRender = ({ project }) => {
    const [isRowExpand, setIsRowExpand] = useState(false)
    const toggleRowExpand = () => setIsRowExpand(!isRowExpand)

    return (
        <View style={{ gap: 10 }}>
            <RowReport isRowExpand={isRowExpand} toggleRowExpand={toggleRowExpand} key={project.id} data={project} />

            {project.groupedTasks.length ? (
                <View style={{ display: isRowExpand ? 'flex' : 'none', gap: 10, alignItems: 'flex-end' }}>
                    {project.groupedTasks?.map((insideTak: any, index: number) => (
                        <RowReport
                            isChildRow
                            isRowExpand={isRowExpand}
                            toggleRowExpand={toggleRowExpand}
                            key={index}
                            data={insideTak}
                        />
                    ))}
                </View>
            ) : null}
        </View>
    )
}

export const RenderOptionsDatePicker = ({ dateRange, setDateRange }) => {
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()
    const { closeShowOverlay } = useOverlayProvider()

    return (
        <View
            style={[
                {
                    backgroundColor: styles.filterBackground,
                    borderColor: styles.filterBorderColor,
                    width: windowWidth <= 540 ? 'auto' : 170,
                },
                localStyles.containerOptionDatePicker,
            ]}
        >
            {OPTIONS_DATES_RANGE.map(option => (
                <Pressable
                    style={
                        option.label === dateRange?.label
                            ? {
                                  backgroundColor: '#ff316217',
                                  paddingVertical: 4,
                                  paddingLeft: 2,
                              }
                            : null
                    }
                    onPress={() => {
                        setDateRange(option)
                        closeShowOverlay()
                    }}
                >
                    <Text
                        style={{
                            fontSize: 16,
                            fontWeight: '700',
                            color: option.label === dateRange?.label ? '#FF3162' : styles.text,
                        }}
                    >
                        {option.label}
                    </Text>
                </Pressable>
            ))}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 18,
        paddingTop: 35,
        backgroundColor: '#ffffff',
    },
    containerVictoryBar: {
        width: '100%',
        height: 337,
        borderRadius: 6,
        marginHorizontal: 29,
        marginVertical: 20,
    },
    containerProgressBar: {
        flexDirection: 'column',
        height: 232,
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center',
    },
    subcontainerProgressBar: {
        paddingHorizontal: 6,
        marginHorizontal: 40,
        justifyContent: 'center',
        width: 150,
        height: 150,
        borderRadius: 150 / 2,
        borderColor: '#c9c9c9',
        borderWidth: 1,
        alignItems: 'center',
    },
    progressBarText: {
        width: '75%',
        textAlign: 'center',
        position: 'absolute',
        fontSize: 18,
        fontWeight: '300',
    },
    containerOptionDatePicker: {
        flexDirection: 'column',
        columnGap: 10,
        rowGap: 18,
        borderWidth: 1,
        padding: 10,
    },
    legendsContainer: {
        marginTop: 20,
        flexDirection: 'row',
        gap: 30,
        borderRadius: 10,
        paddingVertical: 16,
        paddingHorizontal: 10,
        flexWrap: 'wrap',
    },
})

export default Reports
