/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://bh5djmikjl.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "stripeHandlerApi",
            "endpoint": "https://r7nud2dpo8.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bhcjlnqafzh3jogar3kqdhs4bu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tz4z3wkglnannooodx4u6hk4gq",
    "aws_cognito_identity_pool_id": "us-east-1:4d3c3f0e-269c-4e57-ac6d-94d9b067d7ab",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_F3FkuNBSZ",
    "aws_user_pools_web_client_id": "c2it9on4olpcrtaduip5nt144",
    "oauth": {
        "domain": "timersrn912676d2-912676d2-test.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,https://www.dev.app.timerz.io/,https://www.staging.app.timerz.io/",
        "redirectSignOut": "http://localhost:19006/,https://www.dev.app.timerz.io/,https://www.staging.app.timerz.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "timersrn85e5a921bae548a290c1c15cbcbc8a683497f-test",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
