/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ProcessOrderInput = {
  id: string,
  cart?: Array< CartItem | null > | null,
  total: number,
  token: string,
  name?: string | null,
  email?: string | null,
  sub?: string | null,
  priceId?: string | null,
  seatsAmount?: number | null,
  couponCode?: string | null,
};

export type CartItem = {
  id: string,
  title?: string | null,
  price?: number | null,
  amount?: number | null,
};

export type OrderResult = {
  __typename: "OrderResult",
  status?: OrderStatus | null,
  clientSecret?: string | null,
  customerId?: string | null,
};

export enum OrderStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}


export type GetCouponInput = {
  coupon?: string | null,
};

export type StripeCoupon = {
  __typename: "StripeCoupon",
  name?: string | null,
  valid?: boolean | null,
  isExistingCoupon?: boolean | null,
  percent_off?: number | null,
};

export type GetInvoicesInput = {
  customerId: string,
  lastInvoice?: string | null,
  invoiceLimit?: number | null,
};

export type InvoicesByCustomer = {
  __typename: "InvoicesByCustomer",
  invoices?:  Array<Invoice | null > | null,
  subscription?: CurrentSubscription | null,
  paymentMethod?: PaymentMethod | null,
};

export type Invoice = {
  __typename: "Invoice",
  id?: string | null,
  created?: number | null,
  total?: number | null,
  invoice_pdf?: string | null,
  seats?: number | null,
};

export type CurrentSubscription = {
  __typename: "CurrentSubscription",
  id?: string | null,
  planId?: string | null,
  amount?: number | null,
  interval?: string | null,
  seats?: number | null,
  subscriptionsItems?:  Array<SubscriptionItem | null > | null,
};

export type SubscriptionItem = {
  __typename: "SubscriptionItem",
  id?: string | null,
  quantity?: number | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  id?: string | null,
  billing_details?: Billing_details | null,
  card?: Billing_card | null,
};

export type Billing_details = {
  __typename: "Billing_details",
  name?: string | null,
};

export type Billing_card = {
  __typename: "Billing_card",
  last4?: string | null,
  brand?: string | null,
  exp_month?: number | null,
  exp_year?: number | null,
  display_brand?: string | null,
};

export type InputGetReportPdf = {
  reportData?: Array< DataReport | null > | null,
  tasksByProjects?: Array< TasksByProjects | null > | null,
  dateRange?: Array< string | null > | null,
  totalTime?: string | null,
  workSpaceName?: string | null,
  headerTitle?: string | null,
  isDetailed?: boolean | null,
  imageProjectChart?: string | null,
  imageGeneralReport?: string | null,
  imageTasksChart?: string | null,
};

export type DataReport = {
  name?: string | null,
  tasks?: string | null,
  duration?: string | null,
  name_project?: string | null,
  range_duration?: string | null,
  porcentage?: string | null,
  date?: string | null,
  pomodoro?: number | null,
  projectColor?: string | null,
};

export type TasksByProjects = {
  duration?: string | null,
  name_project?: string | null,
  projectColor?: string | null,
  porcentage?: string | null,
};

export type billingInput = {
  subscription?: string | null,
  customer?: string | null,
  value?: number | null,
};

export type subscriptionResponse = {
  __typename: "subscriptionResponse",
  statusCode?: number | null,
  body?: string | null,
};

export type updatePaymentMethodInput = {
  subscriptionId?: string | null,
  customerId?: string | null,
  paymentMethodId?: string | null,
};

export type updatePlanInput = {
  subscriptionId?: string | null,
  newPlanId?: string | null,
  subscriptionItemId?: string | null,
  subscriptionSeats?: number | null,
};

export type invoiceInput = {
  invoiceId?: string | null,
};

export type createProblemInput = {
  description?: string | null,
  email?: string | null,
  files?: string | null,
};

export type CreateOrderInput = {
  id?: string | null,
  usersub?: string | null,
  date?: string | null,
  total?: number | null,
  subscriptionId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelOrderConditionInput = {
  usersub?: ModelStringInput | null,
  date?: ModelStringInput | null,
  total?: ModelFloatInput | null,
  subscriptionId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  usersub?: string | null,
  date?: string | null,
  total?: number | null,
  subscriptionId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  plans?: ModelByOrderConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelByOrderConnection = {
  __typename: "ModelByOrderConnection",
  items:  Array<ByOrder | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ByOrder = {
  __typename: "ByOrder",
  id: string,
  orderID: string,
  planID: string,
  order: Order,
  plan: Plan,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Plan = {
  __typename: "Plan",
  id: string,
  name?: string | null,
  description?: string | null,
  price?: number | null,
  max_users?: number | null,
  max_projects?: number | null,
  max_clients?: number | null,
  max_groups?: number | null,
  status?: Status | null,
  priceIdStripe?: string | null,
  membership?: TypeMembership | null,
  Organization?: ModelOrganizationConnection | null,
  orders?: ModelByOrderConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export enum TypeMembership {
  FREE = "FREE",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}


export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name?: string | null,
  planID: string,
  plan?: Plan | null,
  status?: Status | null,
  pomodoroTime?: number | null,
  shortBreakTime?: number | null,
  longBreakTime?: number | null,
  automaticStartTime?: number | null,
  editionTimeFrame?: string | null,
  created_by?: string | null,
  subscribedSeats?: number | null,
  OrganizationUser?: ModelOrganizationUserConnection | null,
  Invitations?: ModelInvitationConnection | null,
  Clients?: ModelClientConnection | null,
  Projects?: ModelProjectConnection | null,
  Tasks?: ModelTaskConnection | null,
  Groups?: ModelGroupConnection | null,
  Tags?: ModelTagConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelOrganizationUserConnection = {
  __typename: "ModelOrganizationUserConnection",
  items:  Array<OrganizationUser | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OrganizationUser = {
  __typename: "OrganizationUser",
  id: string,
  organizationID: string,
  organization?: Organization | null,
  email?: string | null,
  usersub?: string | null,
  role?: Role | null,
  is_default?: boolean | null,
  status?: StatusOrganizationUser | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userID: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum Role {
  USER = "USER",
  ADMIN = "ADMIN",
}


export enum StatusOrganizationUser {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelInvitationConnection = {
  __typename: "ModelInvitationConnection",
  items:  Array<Invitation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Invitation = {
  __typename: "Invitation",
  id: string,
  organizationID: string,
  organization?: Organization | null,
  email?: string | null,
  invitedOn?: string | null,
  invitedBy?: string | null,
  user?: User | null,
  role?: Role | null,
  status?: StatusInvitation | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type User = {
  __typename: "User",
  id: string,
  givenName?: string | null,
  familyName?: string | null,
  name?: string | null,
  email?: string | null,
  timeZone?: string | null,
  dateFormat?: string | null,
  timeFormat?: string | null,
  image?: string | null,
  themeMode?: string | null,
  accepTerms?: string | null,
  newUser?: boolean | null,
  customerIdStripe?: string | null,
  disabled?: boolean | null,
  OrganizationUsers?: ModelOrganizationUserConnection | null,
  Invitations?: ModelInvitationConnection | null,
  Groups?: ModelUserGroupConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelUserGroupConnection = {
  __typename: "ModelUserGroupConnection",
  items:  Array<UserGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UserGroup = {
  __typename: "UserGroup",
  id: string,
  userID: string,
  groupID: string,
  user: User,
  group: Group,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Group = {
  __typename: "Group",
  id: string,
  name: string,
  status?: Status | null,
  organizationID: string,
  Projects?: ModelProjectConnection | null,
  UsersGroup?: ModelUserGroupConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Project = {
  __typename: "Project",
  id: string,
  name: string,
  description?: string | null,
  status: StatusProject,
  groupcolor?: string | null,
  clientID?: string | null,
  client?: Client | null,
  groupID?: string | null,
  group?: Group | null,
  organizationID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum StatusProject {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export type Client = {
  __typename: "Client",
  id: string,
  name: string,
  TagsClient?: ModelClientTagConnection | null,
  status: StatusClient,
  organizationID: string,
  Projects?: ModelProjectConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelClientTagConnection = {
  __typename: "ModelClientTagConnection",
  items:  Array<ClientTag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ClientTag = {
  __typename: "ClientTag",
  id: string,
  tagID: string,
  clientID: string,
  tag: Tag,
  client: Client,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  name: string,
  color?: string | null,
  status?: StatusTag | null,
  organizationID: string,
  Clients?: ModelClientTagConnection | null,
  Tasks?: ModelTaskTagConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum StatusTag {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export type ModelTaskTagConnection = {
  __typename: "ModelTaskTagConnection",
  items:  Array<TaskTag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TaskTag = {
  __typename: "TaskTag",
  id: string,
  tagID: string,
  taskID: string,
  tag: Tag,
  task: Task,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Task = {
  __typename: "Task",
  id: string,
  name?: string | null,
  time?: string | null,
  usersub?: string | null,
  type?: TypeTask | null,
  status?: StatusTask | null,
  hasTimeEdited?: boolean | null,
  projectID?: string | null,
  organizationID: string,
  project?: Project | null,
  TagsTask?: ModelTaskTagConnection | null,
  searchableText?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deleted?: boolean | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum TypeTask {
  FOCUS = "FOCUS",
  SBREAK = "SBREAK",
  LBREAK = "LBREAK",
}


export enum StatusTask {
  FINISHED = "FINISHED",
  COMPLETED = "COMPLETED",
  RUNNING = "RUNNING",
}


export enum StatusClient {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export enum StatusInvitation {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
}


export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTaskConnection = {
  __typename: "ModelTaskConnection",
  items:  Array<Task | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items:  Array<Group | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection",
  items:  Array<Tag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateOrderInput = {
  id: string,
  usersub?: string | null,
  date?: string | null,
  total?: number | null,
  subscriptionId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteOrderInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvitationInput = {
  id?: string | null,
  organizationID: string,
  email?: string | null,
  invitedOn?: string | null,
  invitedBy?: string | null,
  role?: Role | null,
  status?: StatusInvitation | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelInvitationConditionInput = {
  organizationID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  invitedOn?: ModelStringInput | null,
  invitedBy?: ModelIDInput | null,
  role?: ModelRoleInput | null,
  status?: ModelStatusInvitationInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInvitationConditionInput | null > | null,
  or?: Array< ModelInvitationConditionInput | null > | null,
  not?: ModelInvitationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelRoleInput = {
  eq?: Role | null,
  ne?: Role | null,
};

export type ModelStatusInvitationInput = {
  eq?: StatusInvitation | null,
  ne?: StatusInvitation | null,
};

export type UpdateInvitationInput = {
  id: string,
  organizationID?: string | null,
  email?: string | null,
  invitedOn?: string | null,
  invitedBy?: string | null,
  role?: Role | null,
  status?: StatusInvitation | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteInvitationInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  name?: string | null,
  email?: string | null,
  timeZone?: string | null,
  dateFormat?: string | null,
  timeFormat?: string | null,
  image?: string | null,
  themeMode?: string | null,
  accepTerms?: string | null,
  newUser?: boolean | null,
  customerIdStripe?: string | null,
  disabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  dateFormat?: ModelStringInput | null,
  timeFormat?: ModelStringInput | null,
  image?: ModelStringInput | null,
  themeMode?: ModelStringInput | null,
  accepTerms?: ModelStringInput | null,
  newUser?: ModelBooleanInput | null,
  customerIdStripe?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateUserInput = {
  id: string,
  givenName?: string | null,
  familyName?: string | null,
  name?: string | null,
  email?: string | null,
  timeZone?: string | null,
  dateFormat?: string | null,
  timeFormat?: string | null,
  image?: string | null,
  themeMode?: string | null,
  accepTerms?: string | null,
  newUser?: boolean | null,
  customerIdStripe?: string | null,
  disabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrganizationUserInput = {
  id?: string | null,
  organizationID: string,
  email?: string | null,
  usersub?: string | null,
  role?: Role | null,
  is_default?: boolean | null,
  status?: StatusOrganizationUser | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userID: string,
  _version?: number | null,
};

export type ModelOrganizationUserConditionInput = {
  organizationID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  usersub?: ModelStringInput | null,
  role?: ModelRoleInput | null,
  is_default?: ModelBooleanInput | null,
  status?: ModelStatusOrganizationUserInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelOrganizationUserConditionInput | null > | null,
  or?: Array< ModelOrganizationUserConditionInput | null > | null,
  not?: ModelOrganizationUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatusOrganizationUserInput = {
  eq?: StatusOrganizationUser | null,
  ne?: StatusOrganizationUser | null,
};

export type UpdateOrganizationUserInput = {
  id: string,
  organizationID?: string | null,
  email?: string | null,
  usersub?: string | null,
  role?: Role | null,
  is_default?: boolean | null,
  status?: StatusOrganizationUser | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userID?: string | null,
  _version?: number | null,
};

export type DeleteOrganizationUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name?: string | null,
  planID: string,
  status?: Status | null,
  pomodoroTime?: number | null,
  shortBreakTime?: number | null,
  longBreakTime?: number | null,
  automaticStartTime?: number | null,
  editionTimeFrame?: string | null,
  created_by?: string | null,
  subscribedSeats?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  planID?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  pomodoroTime?: ModelFloatInput | null,
  shortBreakTime?: ModelFloatInput | null,
  longBreakTime?: ModelFloatInput | null,
  automaticStartTime?: ModelIntInput | null,
  editionTimeFrame?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  subscribedSeats?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  planID?: string | null,
  status?: Status | null,
  pomodoroTime?: number | null,
  shortBreakTime?: number | null,
  longBreakTime?: number | null,
  automaticStartTime?: number | null,
  editionTimeFrame?: string | null,
  created_by?: string | null,
  subscribedSeats?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteOrganizationInput = {
  id: string,
  _version?: number | null,
};

export type CreatePlanInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  price?: number | null,
  max_users?: number | null,
  max_projects?: number | null,
  max_clients?: number | null,
  max_groups?: number | null,
  status?: Status | null,
  priceIdStripe?: string | null,
  membership?: TypeMembership | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelPlanConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  max_users?: ModelIntInput | null,
  max_projects?: ModelIntInput | null,
  max_clients?: ModelIntInput | null,
  max_groups?: ModelIntInput | null,
  status?: ModelStatusInput | null,
  priceIdStripe?: ModelStringInput | null,
  membership?: ModelTypeMembershipInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPlanConditionInput | null > | null,
  or?: Array< ModelPlanConditionInput | null > | null,
  not?: ModelPlanConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTypeMembershipInput = {
  eq?: TypeMembership | null,
  ne?: TypeMembership | null,
};

export type UpdatePlanInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  price?: number | null,
  max_users?: number | null,
  max_projects?: number | null,
  max_clients?: number | null,
  max_groups?: number | null,
  status?: Status | null,
  priceIdStripe?: string | null,
  membership?: TypeMembership | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeletePlanInput = {
  id: string,
  _version?: number | null,
};

export type CreateTagInput = {
  id?: string | null,
  name: string,
  color?: string | null,
  status?: StatusTag | null,
  organizationID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelTagConditionInput = {
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  status?: ModelStatusTagInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTagConditionInput | null > | null,
  or?: Array< ModelTagConditionInput | null > | null,
  not?: ModelTagConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatusTagInput = {
  eq?: StatusTag | null,
  ne?: StatusTag | null,
};

export type UpdateTagInput = {
  id: string,
  name?: string | null,
  color?: string | null,
  status?: StatusTag | null,
  organizationID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteTagInput = {
  id: string,
  _version?: number | null,
};

export type CreateGroupInput = {
  id?: string | null,
  name: string,
  status?: Status | null,
  organizationID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelGroupConditionInput = {
  name?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateGroupInput = {
  id: string,
  name?: string | null,
  status?: Status | null,
  organizationID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientInput = {
  id?: string | null,
  name: string,
  status: StatusClient,
  organizationID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelClientConditionInput = {
  name?: ModelStringInput | null,
  status?: ModelStatusClientInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatusClientInput = {
  eq?: StatusClient | null,
  ne?: StatusClient | null,
};

export type UpdateClientInput = {
  id: string,
  name?: string | null,
  status?: StatusClient | null,
  organizationID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteClientInput = {
  id: string,
  _version?: number | null,
};

export type CreateProjectInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  status: StatusProject,
  groupcolor?: string | null,
  clientID?: string | null,
  groupID?: string | null,
  organizationID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelProjectConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStatusProjectInput | null,
  groupcolor?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatusProjectInput = {
  eq?: StatusProject | null,
  ne?: StatusProject | null,
};

export type UpdateProjectInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  status?: StatusProject | null,
  groupcolor?: string | null,
  clientID?: string | null,
  groupID?: string | null,
  organizationID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteProjectInput = {
  id: string,
  _version?: number | null,
};

export type CreateTaskInput = {
  id?: string | null,
  name?: string | null,
  time?: string | null,
  usersub?: string | null,
  type?: TypeTask | null,
  status?: StatusTask | null,
  hasTimeEdited?: boolean | null,
  projectID?: string | null,
  organizationID: string,
  searchableText?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deleted?: boolean | null,
  _version?: number | null,
};

export type ModelTaskConditionInput = {
  name?: ModelStringInput | null,
  time?: ModelStringInput | null,
  usersub?: ModelStringInput | null,
  type?: ModelTypeTaskInput | null,
  status?: ModelStatusTaskInput | null,
  hasTimeEdited?: ModelBooleanInput | null,
  projectID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  searchableText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelTaskConditionInput | null > | null,
  or?: Array< ModelTaskConditionInput | null > | null,
  not?: ModelTaskConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTypeTaskInput = {
  eq?: TypeTask | null,
  ne?: TypeTask | null,
};

export type ModelStatusTaskInput = {
  eq?: StatusTask | null,
  ne?: StatusTask | null,
};

export type UpdateTaskInput = {
  id: string,
  name?: string | null,
  time?: string | null,
  usersub?: string | null,
  type?: TypeTask | null,
  status?: StatusTask | null,
  hasTimeEdited?: boolean | null,
  projectID?: string | null,
  organizationID?: string | null,
  searchableText?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deleted?: boolean | null,
  _version?: number | null,
};

export type DeleteTaskInput = {
  id: string,
  _version?: number | null,
};

export type CreateByOrderInput = {
  id?: string | null,
  orderID: string,
  planID: string,
  _version?: number | null,
};

export type ModelByOrderConditionInput = {
  orderID?: ModelIDInput | null,
  planID?: ModelIDInput | null,
  and?: Array< ModelByOrderConditionInput | null > | null,
  or?: Array< ModelByOrderConditionInput | null > | null,
  not?: ModelByOrderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateByOrderInput = {
  id: string,
  orderID?: string | null,
  planID?: string | null,
  _version?: number | null,
};

export type DeleteByOrderInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserGroupInput = {
  id?: string | null,
  userID: string,
  groupID: string,
  _version?: number | null,
};

export type ModelUserGroupConditionInput = {
  userID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  and?: Array< ModelUserGroupConditionInput | null > | null,
  or?: Array< ModelUserGroupConditionInput | null > | null,
  not?: ModelUserGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserGroupInput = {
  id: string,
  userID?: string | null,
  groupID?: string | null,
  _version?: number | null,
};

export type DeleteUserGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientTagInput = {
  id?: string | null,
  tagID: string,
  clientID: string,
  _version?: number | null,
};

export type ModelClientTagConditionInput = {
  tagID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  and?: Array< ModelClientTagConditionInput | null > | null,
  or?: Array< ModelClientTagConditionInput | null > | null,
  not?: ModelClientTagConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateClientTagInput = {
  id: string,
  tagID?: string | null,
  clientID?: string | null,
  _version?: number | null,
};

export type DeleteClientTagInput = {
  id: string,
  _version?: number | null,
};

export type CreateTaskTagInput = {
  id?: string | null,
  tagID: string,
  taskID: string,
  _version?: number | null,
};

export type ModelTaskTagConditionInput = {
  tagID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  and?: Array< ModelTaskTagConditionInput | null > | null,
  or?: Array< ModelTaskTagConditionInput | null > | null,
  not?: ModelTaskTagConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTaskTagInput = {
  id: string,
  tagID?: string | null,
  taskID?: string | null,
  _version?: number | null,
};

export type DeleteTaskTagInput = {
  id: string,
  _version?: number | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  usersub?: ModelStringInput | null,
  date?: ModelStringInput | null,
  total?: ModelFloatInput | null,
  subscriptionId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInvitationFilterInput = {
  id?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  invitedOn?: ModelStringInput | null,
  invitedBy?: ModelIDInput | null,
  role?: ModelRoleInput | null,
  status?: ModelStatusInvitationInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInvitationFilterInput | null > | null,
  or?: Array< ModelInvitationFilterInput | null > | null,
  not?: ModelInvitationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  dateFormat?: ModelStringInput | null,
  timeFormat?: ModelStringInput | null,
  image?: ModelStringInput | null,
  themeMode?: ModelStringInput | null,
  accepTerms?: ModelStringInput | null,
  newUser?: ModelBooleanInput | null,
  customerIdStripe?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOrganizationUserFilterInput = {
  id?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  usersub?: ModelStringInput | null,
  role?: ModelRoleInput | null,
  is_default?: ModelBooleanInput | null,
  status?: ModelStatusOrganizationUserInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelOrganizationUserFilterInput | null > | null,
  or?: Array< ModelOrganizationUserFilterInput | null > | null,
  not?: ModelOrganizationUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  planID?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  pomodoroTime?: ModelFloatInput | null,
  shortBreakTime?: ModelFloatInput | null,
  longBreakTime?: ModelFloatInput | null,
  automaticStartTime?: ModelIntInput | null,
  editionTimeFrame?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  subscribedSeats?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPlanFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  max_users?: ModelIntInput | null,
  max_projects?: ModelIntInput | null,
  max_clients?: ModelIntInput | null,
  max_groups?: ModelIntInput | null,
  status?: ModelStatusInput | null,
  priceIdStripe?: ModelStringInput | null,
  membership?: ModelTypeMembershipInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPlanFilterInput | null > | null,
  or?: Array< ModelPlanFilterInput | null > | null,
  not?: ModelPlanFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPlanConnection = {
  __typename: "ModelPlanConnection",
  items:  Array<Plan | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  status?: ModelStatusTagInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTagFilterInput | null > | null,
  or?: Array< ModelTagFilterInput | null > | null,
  not?: ModelTagFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelStatusClientInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStatusProjectInput | null,
  groupcolor?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTaskFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  time?: ModelStringInput | null,
  usersub?: ModelStringInput | null,
  type?: ModelTypeTaskInput | null,
  status?: ModelStatusTaskInput | null,
  hasTimeEdited?: ModelBooleanInput | null,
  projectID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  searchableText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelTaskFilterInput | null > | null,
  or?: Array< ModelTaskFilterInput | null > | null,
  not?: ModelTaskFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelByOrderFilterInput = {
  id?: ModelIDInput | null,
  orderID?: ModelIDInput | null,
  planID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelByOrderFilterInput | null > | null,
  or?: Array< ModelByOrderFilterInput | null > | null,
  not?: ModelByOrderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserGroupFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserGroupFilterInput | null > | null,
  or?: Array< ModelUserGroupFilterInput | null > | null,
  not?: ModelUserGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelClientTagFilterInput = {
  id?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClientTagFilterInput | null > | null,
  or?: Array< ModelClientTagFilterInput | null > | null,
  not?: ModelClientTagFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTaskTagFilterInput = {
  id?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTaskTagFilterInput | null > | null,
  or?: Array< ModelTaskTagFilterInput | null > | null,
  not?: ModelTaskTagFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInvitationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  invitedOn?: ModelSubscriptionStringInput | null,
  invitedBy?: ModelSubscriptionIDInput | null,
  role?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInvitationFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvitationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  familyName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  timeZone?: ModelSubscriptionStringInput | null,
  dateFormat?: ModelSubscriptionStringInput | null,
  timeFormat?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  themeMode?: ModelSubscriptionStringInput | null,
  accepTerms?: ModelSubscriptionStringInput | null,
  newUser?: ModelSubscriptionBooleanInput | null,
  customerIdStripe?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionOrganizationUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  usersub?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  is_default?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionOrganizationUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  planID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  pomodoroTime?: ModelSubscriptionFloatInput | null,
  shortBreakTime?: ModelSubscriptionFloatInput | null,
  longBreakTime?: ModelSubscriptionFloatInput | null,
  automaticStartTime?: ModelSubscriptionIntInput | null,
  editionTimeFrame?: ModelSubscriptionStringInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  subscribedSeats?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPlanFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  max_users?: ModelSubscriptionIntInput | null,
  max_projects?: ModelSubscriptionIntInput | null,
  max_clients?: ModelSubscriptionIntInput | null,
  max_groups?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  priceIdStripe?: ModelSubscriptionStringInput | null,
  membership?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPlanFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlanFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionTagFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  groupcolor?: ModelSubscriptionStringInput | null,
  clientID?: ModelSubscriptionIDInput | null,
  groupID?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTaskFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  time?: ModelSubscriptionStringInput | null,
  usersub?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  hasTimeEdited?: ModelSubscriptionBooleanInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  searchableText?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionTaskFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionByOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderID?: ModelSubscriptionIDInput | null,
  planID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionByOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionByOrderFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  groupID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionClientTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tagID?: ModelSubscriptionIDInput | null,
  clientID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientTagFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTaskTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tagID?: ModelSubscriptionIDInput | null,
  taskID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTaskTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskTagFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ProcessOrderMutationVariables = {
  input: ProcessOrderInput,
};

export type ProcessOrderMutation = {
  processOrder?:  {
    __typename: "OrderResult",
    status?: OrderStatus | null,
    clientSecret?: string | null,
    customerId?: string | null,
  } | null,
};

export type GetStripeCouponMutationVariables = {
  input?: GetCouponInput | null,
};

export type GetStripeCouponMutation = {
  getStripeCoupon?:  {
    __typename: "StripeCoupon",
    name?: string | null,
    valid?: boolean | null,
    isExistingCoupon?: boolean | null,
    percent_off?: number | null,
  } | null,
};

export type GetInvoicesByCustomerMutationVariables = {
  input?: GetInvoicesInput | null,
};

export type GetInvoicesByCustomerMutation = {
  getInvoicesByCustomer?:  {
    __typename: "InvoicesByCustomer",
    invoices?:  Array< {
      __typename: "Invoice",
      id?: string | null,
      created?: number | null,
      total?: number | null,
      invoice_pdf?: string | null,
      seats?: number | null,
    } | null > | null,
    subscription?:  {
      __typename: "CurrentSubscription",
      id?: string | null,
      planId?: string | null,
      amount?: number | null,
      interval?: string | null,
      seats?: number | null,
      subscriptionsItems?:  Array< {
        __typename: "SubscriptionItem",
        id?: string | null,
        quantity?: number | null,
      } | null > | null,
    } | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id?: string | null,
      billing_details?:  {
        __typename: "Billing_details",
        name?: string | null,
      } | null,
      card?:  {
        __typename: "Billing_card",
        last4?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        display_brand?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetReportPdfMutationMutationVariables = {
  input?: InputGetReportPdf | null,
};

export type GetReportPdfMutationMutation = {
  getReportPdfMutation?: string | null,
};

export type GetSubscriptionsByCustomerMutationVariables = {
  input: billingInput,
};

export type GetSubscriptionsByCustomerMutation = {
  getSubscriptionsByCustomer?:  {
    __typename: "subscriptionResponse",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type CancelSubscriptionByIdMutationVariables = {
  input: billingInput,
};

export type CancelSubscriptionByIdMutation = {
  cancelSubscriptionById?:  {
    __typename: "subscriptionResponse",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type UpdateSubscriptionPaymentMethodMutationVariables = {
  input: updatePaymentMethodInput,
};

export type UpdateSubscriptionPaymentMethodMutation = {
  updateSubscriptionPaymentMethod?:  {
    __typename: "subscriptionResponse",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type UpdateSubscriptionPlanMutationVariables = {
  input: updatePlanInput,
};

export type UpdateSubscriptionPlanMutation = {
  updateSubscriptionPlan?:  {
    __typename: "subscriptionResponse",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type GetInvoiceUrlMutationVariables = {
  input: invoiceInput,
};

export type GetInvoiceUrlMutation = {
  getInvoiceUrl?:  {
    __typename: "subscriptionResponse",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type CreateProblemReportMutationVariables = {
  input: createProblemInput,
};

export type CreateProblemReportMutation = {
  createProblemReport?:  {
    __typename: "subscriptionResponse",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    usersub?: string | null,
    date?: string | null,
    total?: number | null,
    subscriptionId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    plans?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    usersub?: string | null,
    date?: string | null,
    total?: number | null,
    subscriptionId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    plans?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    usersub?: string | null,
    date?: string | null,
    total?: number | null,
    subscriptionId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    plans?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvitationMutationVariables = {
  input: CreateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type CreateInvitationMutation = {
  createInvitation?:  {
    __typename: "Invitation",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    invitedOn?: string | null,
    invitedBy?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    role?: Role | null,
    status?: StatusInvitation | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: UpdateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type UpdateInvitationMutation = {
  updateInvitation?:  {
    __typename: "Invitation",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    invitedOn?: string | null,
    invitedBy?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    role?: Role | null,
    status?: StatusInvitation | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  input: DeleteInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type DeleteInvitationMutation = {
  deleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    invitedOn?: string | null,
    invitedBy?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    role?: Role | null,
    status?: StatusInvitation | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    givenName?: string | null,
    familyName?: string | null,
    name?: string | null,
    email?: string | null,
    timeZone?: string | null,
    dateFormat?: string | null,
    timeFormat?: string | null,
    image?: string | null,
    themeMode?: string | null,
    accepTerms?: string | null,
    newUser?: boolean | null,
    customerIdStripe?: string | null,
    disabled?: boolean | null,
    OrganizationUsers?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    givenName?: string | null,
    familyName?: string | null,
    name?: string | null,
    email?: string | null,
    timeZone?: string | null,
    dateFormat?: string | null,
    timeFormat?: string | null,
    image?: string | null,
    themeMode?: string | null,
    accepTerms?: string | null,
    newUser?: boolean | null,
    customerIdStripe?: string | null,
    disabled?: boolean | null,
    OrganizationUsers?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    givenName?: string | null,
    familyName?: string | null,
    name?: string | null,
    email?: string | null,
    timeZone?: string | null,
    dateFormat?: string | null,
    timeFormat?: string | null,
    image?: string | null,
    themeMode?: string | null,
    accepTerms?: string | null,
    newUser?: boolean | null,
    customerIdStripe?: string | null,
    disabled?: boolean | null,
    OrganizationUsers?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrganizationUserMutationVariables = {
  input: CreateOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput | null,
};

export type CreateOrganizationUserMutation = {
  createOrganizationUser?:  {
    __typename: "OrganizationUser",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    usersub?: string | null,
    role?: Role | null,
    is_default?: boolean | null,
    status?: StatusOrganizationUser | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    userID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrganizationUserMutationVariables = {
  input: UpdateOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput | null,
};

export type UpdateOrganizationUserMutation = {
  updateOrganizationUser?:  {
    __typename: "OrganizationUser",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    usersub?: string | null,
    role?: Role | null,
    is_default?: boolean | null,
    status?: StatusOrganizationUser | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    userID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrganizationUserMutationVariables = {
  input: DeleteOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput | null,
};

export type DeleteOrganizationUserMutation = {
  deleteOrganizationUser?:  {
    __typename: "OrganizationUser",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    usersub?: string | null,
    role?: Role | null,
    is_default?: boolean | null,
    status?: StatusOrganizationUser | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    userID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    planID: string,
    plan?:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: Status | null,
    pomodoroTime?: number | null,
    shortBreakTime?: number | null,
    longBreakTime?: number | null,
    automaticStartTime?: number | null,
    editionTimeFrame?: string | null,
    created_by?: string | null,
    subscribedSeats?: number | null,
    OrganizationUser?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    planID: string,
    plan?:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: Status | null,
    pomodoroTime?: number | null,
    shortBreakTime?: number | null,
    longBreakTime?: number | null,
    automaticStartTime?: number | null,
    editionTimeFrame?: string | null,
    created_by?: string | null,
    subscribedSeats?: number | null,
    OrganizationUser?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    planID: string,
    plan?:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: Status | null,
    pomodoroTime?: number | null,
    shortBreakTime?: number | null,
    longBreakTime?: number | null,
    automaticStartTime?: number | null,
    editionTimeFrame?: string | null,
    created_by?: string | null,
    subscribedSeats?: number | null,
    OrganizationUser?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePlanMutationVariables = {
  input: CreatePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type CreatePlanMutation = {
  createPlan?:  {
    __typename: "Plan",
    id: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    max_users?: number | null,
    max_projects?: number | null,
    max_clients?: number | null,
    max_groups?: number | null,
    status?: Status | null,
    priceIdStripe?: string | null,
    membership?: TypeMembership | null,
    Organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orders?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePlanMutationVariables = {
  input: UpdatePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type UpdatePlanMutation = {
  updatePlan?:  {
    __typename: "Plan",
    id: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    max_users?: number | null,
    max_projects?: number | null,
    max_clients?: number | null,
    max_groups?: number | null,
    status?: Status | null,
    priceIdStripe?: string | null,
    membership?: TypeMembership | null,
    Organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orders?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePlanMutationVariables = {
  input: DeletePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type DeletePlanMutation = {
  deletePlan?:  {
    __typename: "Plan",
    id: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    max_users?: number | null,
    max_projects?: number | null,
    max_clients?: number | null,
    max_groups?: number | null,
    status?: Status | null,
    priceIdStripe?: string | null,
    membership?: TypeMembership | null,
    Organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orders?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type CreateTagMutation = {
  createTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    color?: string | null,
    status?: StatusTag | null,
    organizationID: string,
    Clients?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type UpdateTagMutation = {
  updateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    color?: string | null,
    status?: StatusTag | null,
    organizationID: string,
    Clients?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
  condition?: ModelTagConditionInput | null,
};

export type DeleteTagMutation = {
  deleteTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    color?: string | null,
    status?: StatusTag | null,
    organizationID: string,
    Clients?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGroupMutationVariables = {
  input: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    status?: Status | null,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UsersGroup?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    status?: Status | null,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UsersGroup?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    status?: Status | null,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UsersGroup?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    TagsClient?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status: StatusClient,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    TagsClient?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status: StatusClient,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    TagsClient?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status: StatusClient,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    description?: string | null,
    status: StatusProject,
    groupcolor?: string | null,
    clientID?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    groupID?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    description?: string | null,
    status: StatusProject,
    groupcolor?: string | null,
    clientID?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    groupID?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    description?: string | null,
    status: StatusProject,
    groupcolor?: string | null,
    clientID?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    groupID?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CreateTaskMutation = {
  createTask?:  {
    __typename: "Task",
    id: string,
    name?: string | null,
    time?: string | null,
    usersub?: string | null,
    type?: TypeTask | null,
    status?: StatusTask | null,
    hasTimeEdited?: boolean | null,
    projectID?: string | null,
    organizationID: string,
    project?:  {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    TagsTask?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    searchableText?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deleted?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    id: string,
    name?: string | null,
    time?: string | null,
    usersub?: string | null,
    type?: TypeTask | null,
    status?: StatusTask | null,
    hasTimeEdited?: boolean | null,
    projectID?: string | null,
    organizationID: string,
    project?:  {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    TagsTask?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    searchableText?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deleted?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type DeleteTaskMutation = {
  deleteTask?:  {
    __typename: "Task",
    id: string,
    name?: string | null,
    time?: string | null,
    usersub?: string | null,
    type?: TypeTask | null,
    status?: StatusTask | null,
    hasTimeEdited?: boolean | null,
    projectID?: string | null,
    organizationID: string,
    project?:  {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    TagsTask?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    searchableText?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deleted?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateByOrderMutationVariables = {
  input: CreateByOrderInput,
  condition?: ModelByOrderConditionInput | null,
};

export type CreateByOrderMutation = {
  createByOrder?:  {
    __typename: "ByOrder",
    id: string,
    orderID: string,
    planID: string,
    order:  {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    plan:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateByOrderMutationVariables = {
  input: UpdateByOrderInput,
  condition?: ModelByOrderConditionInput | null,
};

export type UpdateByOrderMutation = {
  updateByOrder?:  {
    __typename: "ByOrder",
    id: string,
    orderID: string,
    planID: string,
    order:  {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    plan:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteByOrderMutationVariables = {
  input: DeleteByOrderInput,
  condition?: ModelByOrderConditionInput | null,
};

export type DeleteByOrderMutation = {
  deleteByOrder?:  {
    __typename: "ByOrder",
    id: string,
    orderID: string,
    planID: string,
    order:  {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    plan:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserGroupMutationVariables = {
  input: CreateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CreateUserGroupMutation = {
  createUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userID: string,
    groupID: string,
    user:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserGroupMutationVariables = {
  input: UpdateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupMutation = {
  updateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userID: string,
    groupID: string,
    user:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserGroupMutationVariables = {
  input: DeleteUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type DeleteUserGroupMutation = {
  deleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userID: string,
    groupID: string,
    user:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClientTagMutationVariables = {
  input: CreateClientTagInput,
  condition?: ModelClientTagConditionInput | null,
};

export type CreateClientTagMutation = {
  createClientTag?:  {
    __typename: "ClientTag",
    id: string,
    tagID: string,
    clientID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    client:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClientTagMutationVariables = {
  input: UpdateClientTagInput,
  condition?: ModelClientTagConditionInput | null,
};

export type UpdateClientTagMutation = {
  updateClientTag?:  {
    __typename: "ClientTag",
    id: string,
    tagID: string,
    clientID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    client:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClientTagMutationVariables = {
  input: DeleteClientTagInput,
  condition?: ModelClientTagConditionInput | null,
};

export type DeleteClientTagMutation = {
  deleteClientTag?:  {
    __typename: "ClientTag",
    id: string,
    tagID: string,
    clientID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    client:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTaskTagMutationVariables = {
  input: CreateTaskTagInput,
  condition?: ModelTaskTagConditionInput | null,
};

export type CreateTaskTagMutation = {
  createTaskTag?:  {
    __typename: "TaskTag",
    id: string,
    tagID: string,
    taskID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTaskTagMutationVariables = {
  input: UpdateTaskTagInput,
  condition?: ModelTaskTagConditionInput | null,
};

export type UpdateTaskTagMutation = {
  updateTaskTag?:  {
    __typename: "TaskTag",
    id: string,
    tagID: string,
    taskID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTaskTagMutationVariables = {
  input: DeleteTaskTagInput,
  condition?: ModelTaskTagConditionInput | null,
};

export type DeleteTaskTagMutation = {
  deleteTaskTag?:  {
    __typename: "TaskTag",
    id: string,
    tagID: string,
    taskID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    usersub?: string | null,
    date?: string | null,
    total?: number | null,
    subscriptionId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    plans?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrdersQuery = {
  syncOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvitationQueryVariables = {
  id: string,
};

export type GetInvitationQuery = {
  getInvitation?:  {
    __typename: "Invitation",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    invitedOn?: string | null,
    invitedBy?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    role?: Role | null,
    status?: StatusInvitation | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvitationsQueryVariables = {
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvitationsQuery = {
  listInvitations?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      organizationID: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      email?: string | null,
      invitedOn?: string | null,
      invitedBy?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        givenName?: string | null,
        familyName?: string | null,
        name?: string | null,
        email?: string | null,
        timeZone?: string | null,
        dateFormat?: string | null,
        timeFormat?: string | null,
        image?: string | null,
        themeMode?: string | null,
        accepTerms?: string | null,
        newUser?: boolean | null,
        customerIdStripe?: string | null,
        disabled?: boolean | null,
        OrganizationUsers?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      role?: Role | null,
      status?: StatusInvitation | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvitationsQueryVariables = {
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvitationsQuery = {
  syncInvitations?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      organizationID: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      email?: string | null,
      invitedOn?: string | null,
      invitedBy?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        givenName?: string | null,
        familyName?: string | null,
        name?: string | null,
        email?: string | null,
        timeZone?: string | null,
        dateFormat?: string | null,
        timeFormat?: string | null,
        image?: string | null,
        themeMode?: string | null,
        accepTerms?: string | null,
        newUser?: boolean | null,
        customerIdStripe?: string | null,
        disabled?: boolean | null,
        OrganizationUsers?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      role?: Role | null,
      status?: StatusInvitation | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    givenName?: string | null,
    familyName?: string | null,
    name?: string | null,
    email?: string | null,
    timeZone?: string | null,
    dateFormat?: string | null,
    timeFormat?: string | null,
    image?: string | null,
    themeMode?: string | null,
    accepTerms?: string | null,
    newUser?: boolean | null,
    customerIdStripe?: string | null,
    disabled?: boolean | null,
    OrganizationUsers?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrganizationUserQueryVariables = {
  id: string,
};

export type GetOrganizationUserQuery = {
  getOrganizationUser?:  {
    __typename: "OrganizationUser",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    usersub?: string | null,
    role?: Role | null,
    is_default?: boolean | null,
    status?: StatusOrganizationUser | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    userID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrganizationUsersQueryVariables = {
  filter?: ModelOrganizationUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationUsersQuery = {
  listOrganizationUsers?:  {
    __typename: "ModelOrganizationUserConnection",
    items:  Array< {
      __typename: "OrganizationUser",
      id: string,
      organizationID: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      email?: string | null,
      usersub?: string | null,
      role?: Role | null,
      is_default?: boolean | null,
      status?: StatusOrganizationUser | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      userID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrganizationUsersQueryVariables = {
  filter?: ModelOrganizationUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrganizationUsersQuery = {
  syncOrganizationUsers?:  {
    __typename: "ModelOrganizationUserConnection",
    items:  Array< {
      __typename: "OrganizationUser",
      id: string,
      organizationID: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      email?: string | null,
      usersub?: string | null,
      role?: Role | null,
      is_default?: boolean | null,
      status?: StatusOrganizationUser | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      userID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    planID: string,
    plan?:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: Status | null,
    pomodoroTime?: number | null,
    shortBreakTime?: number | null,
    longBreakTime?: number | null,
    automaticStartTime?: number | null,
    editionTimeFrame?: string | null,
    created_by?: string | null,
    subscribedSeats?: number | null,
    OrganizationUser?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrganizationsQuery = {
  syncOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPlanQueryVariables = {
  id: string,
};

export type GetPlanQuery = {
  getPlan?:  {
    __typename: "Plan",
    id: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    max_users?: number | null,
    max_projects?: number | null,
    max_clients?: number | null,
    max_groups?: number | null,
    status?: Status | null,
    priceIdStripe?: string | null,
    membership?: TypeMembership | null,
    Organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orders?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPlansQueryVariables = {
  filter?: ModelPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlansQuery = {
  listPlans?:  {
    __typename: "ModelPlanConnection",
    items:  Array< {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPlansQueryVariables = {
  filter?: ModelPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPlansQuery = {
  syncPlans?:  {
    __typename: "ModelPlanConnection",
    items:  Array< {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    color?: string | null,
    status?: StatusTag | null,
    organizationID: string,
    Clients?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTagsQuery = {
  syncTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGroupQueryVariables = {
  id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    status?: Status | null,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UsersGroup?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGroupsQuery = {
  syncGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    TagsClient?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status: StatusClient,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientsQuery = {
  syncClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    description?: string | null,
    status: StatusProject,
    groupcolor?: string | null,
    clientID?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    groupID?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProjectsQuery = {
  syncProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTaskQueryVariables = {
  id: string,
};

export type GetTaskQuery = {
  getTask?:  {
    __typename: "Task",
    id: string,
    name?: string | null,
    time?: string | null,
    usersub?: string | null,
    type?: TypeTask | null,
    status?: StatusTask | null,
    hasTimeEdited?: boolean | null,
    projectID?: string | null,
    organizationID: string,
    project?:  {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    TagsTask?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    searchableText?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deleted?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTasksQuery = {
  syncTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetByOrderQueryVariables = {
  id: string,
};

export type GetByOrderQuery = {
  getByOrder?:  {
    __typename: "ByOrder",
    id: string,
    orderID: string,
    planID: string,
    order:  {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    plan:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListByOrdersQueryVariables = {
  filter?: ModelByOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListByOrdersQuery = {
  listByOrders?:  {
    __typename: "ModelByOrderConnection",
    items:  Array< {
      __typename: "ByOrder",
      id: string,
      orderID: string,
      planID: string,
      order:  {
        __typename: "Order",
        id: string,
        usersub?: string | null,
        date?: string | null,
        total?: number | null,
        subscriptionId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        plans?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      plan:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncByOrdersQueryVariables = {
  filter?: ModelByOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncByOrdersQuery = {
  syncByOrders?:  {
    __typename: "ModelByOrderConnection",
    items:  Array< {
      __typename: "ByOrder",
      id: string,
      orderID: string,
      planID: string,
      order:  {
        __typename: "Order",
        id: string,
        usersub?: string | null,
        date?: string | null,
        total?: number | null,
        subscriptionId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        plans?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      plan:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserGroupQueryVariables = {
  id: string,
};

export type GetUserGroupQuery = {
  getUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userID: string,
    groupID: string,
    user:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserGroupsQueryVariables = {
  filter?: ModelUserGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserGroupsQuery = {
  listUserGroups?:  {
    __typename: "ModelUserGroupConnection",
    items:  Array< {
      __typename: "UserGroup",
      id: string,
      userID: string,
      groupID: string,
      user:  {
        __typename: "User",
        id: string,
        givenName?: string | null,
        familyName?: string | null,
        name?: string | null,
        email?: string | null,
        timeZone?: string | null,
        dateFormat?: string | null,
        timeFormat?: string | null,
        image?: string | null,
        themeMode?: string | null,
        accepTerms?: string | null,
        newUser?: boolean | null,
        customerIdStripe?: string | null,
        disabled?: boolean | null,
        OrganizationUsers?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserGroupsQueryVariables = {
  filter?: ModelUserGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserGroupsQuery = {
  syncUserGroups?:  {
    __typename: "ModelUserGroupConnection",
    items:  Array< {
      __typename: "UserGroup",
      id: string,
      userID: string,
      groupID: string,
      user:  {
        __typename: "User",
        id: string,
        givenName?: string | null,
        familyName?: string | null,
        name?: string | null,
        email?: string | null,
        timeZone?: string | null,
        dateFormat?: string | null,
        timeFormat?: string | null,
        image?: string | null,
        themeMode?: string | null,
        accepTerms?: string | null,
        newUser?: boolean | null,
        customerIdStripe?: string | null,
        disabled?: boolean | null,
        OrganizationUsers?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClientTagQueryVariables = {
  id: string,
};

export type GetClientTagQuery = {
  getClientTag?:  {
    __typename: "ClientTag",
    id: string,
    tagID: string,
    clientID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    client:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClientTagsQueryVariables = {
  filter?: ModelClientTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientTagsQuery = {
  listClientTags?:  {
    __typename: "ModelClientTagConnection",
    items:  Array< {
      __typename: "ClientTag",
      id: string,
      tagID: string,
      clientID: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      client:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientTagsQueryVariables = {
  filter?: ModelClientTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientTagsQuery = {
  syncClientTags?:  {
    __typename: "ModelClientTagConnection",
    items:  Array< {
      __typename: "ClientTag",
      id: string,
      tagID: string,
      clientID: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      client:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTaskTagQueryVariables = {
  id: string,
};

export type GetTaskTagQuery = {
  getTaskTag?:  {
    __typename: "TaskTag",
    id: string,
    tagID: string,
    taskID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTaskTagsQueryVariables = {
  filter?: ModelTaskTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskTagsQuery = {
  listTaskTags?:  {
    __typename: "ModelTaskTagConnection",
    items:  Array< {
      __typename: "TaskTag",
      id: string,
      tagID: string,
      taskID: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      task:  {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTaskTagsQueryVariables = {
  filter?: ModelTaskTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTaskTagsQuery = {
  syncTaskTags?:  {
    __typename: "ModelTaskTagConnection",
    items:  Array< {
      __typename: "TaskTag",
      id: string,
      tagID: string,
      taskID: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      task:  {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationFilterInput | null,
};

export type OnCreateInvitationSubscription = {
  onCreateInvitation?:  {
    __typename: "Invitation",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    invitedOn?: string | null,
    invitedBy?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    role?: Role | null,
    status?: StatusInvitation | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationFilterInput | null,
};

export type OnUpdateInvitationSubscription = {
  onUpdateInvitation?:  {
    __typename: "Invitation",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    invitedOn?: string | null,
    invitedBy?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    role?: Role | null,
    status?: StatusInvitation | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationFilterInput | null,
};

export type OnDeleteInvitationSubscription = {
  onDeleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    invitedOn?: string | null,
    invitedBy?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    role?: Role | null,
    status?: StatusInvitation | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    givenName?: string | null,
    familyName?: string | null,
    name?: string | null,
    email?: string | null,
    timeZone?: string | null,
    dateFormat?: string | null,
    timeFormat?: string | null,
    image?: string | null,
    themeMode?: string | null,
    accepTerms?: string | null,
    newUser?: boolean | null,
    customerIdStripe?: string | null,
    disabled?: boolean | null,
    OrganizationUsers?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    givenName?: string | null,
    familyName?: string | null,
    name?: string | null,
    email?: string | null,
    timeZone?: string | null,
    dateFormat?: string | null,
    timeFormat?: string | null,
    image?: string | null,
    themeMode?: string | null,
    accepTerms?: string | null,
    newUser?: boolean | null,
    customerIdStripe?: string | null,
    disabled?: boolean | null,
    OrganizationUsers?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    givenName?: string | null,
    familyName?: string | null,
    name?: string | null,
    email?: string | null,
    timeZone?: string | null,
    dateFormat?: string | null,
    timeFormat?: string | null,
    image?: string | null,
    themeMode?: string | null,
    accepTerms?: string | null,
    newUser?: boolean | null,
    customerIdStripe?: string | null,
    disabled?: boolean | null,
    OrganizationUsers?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrganizationUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationUserFilterInput | null,
};

export type OnCreateOrganizationUserSubscription = {
  onCreateOrganizationUser?:  {
    __typename: "OrganizationUser",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    usersub?: string | null,
    role?: Role | null,
    is_default?: boolean | null,
    status?: StatusOrganizationUser | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    userID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOrganizationUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationUserFilterInput | null,
};

export type OnUpdateOrganizationUserSubscription = {
  onUpdateOrganizationUser?:  {
    __typename: "OrganizationUser",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    usersub?: string | null,
    role?: Role | null,
    is_default?: boolean | null,
    status?: StatusOrganizationUser | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    userID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOrganizationUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationUserFilterInput | null,
};

export type OnDeleteOrganizationUserSubscription = {
  onDeleteOrganizationUser?:  {
    __typename: "OrganizationUser",
    id: string,
    organizationID: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      planID: string,
      plan?:  {
        __typename: "Plan",
        id: string,
        name?: string | null,
        description?: string | null,
        price?: number | null,
        max_users?: number | null,
        max_projects?: number | null,
        max_clients?: number | null,
        max_groups?: number | null,
        status?: Status | null,
        priceIdStripe?: string | null,
        membership?: TypeMembership | null,
        Organization?:  {
          __typename: "ModelOrganizationConnection",
          items:  Array< {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orders?:  {
          __typename: "ModelByOrderConnection",
          items:  Array< {
            __typename: "ByOrder",
            id: string,
            orderID: string,
            planID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: Status | null,
      pomodoroTime?: number | null,
      shortBreakTime?: number | null,
      longBreakTime?: number | null,
      automaticStartTime?: number | null,
      editionTimeFrame?: string | null,
      created_by?: string | null,
      subscribedSeats?: number | null,
      OrganizationUser?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskConnection",
        items:  Array< {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tags?:  {
        __typename: "ModelTagConnection",
        items:  Array< {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    usersub?: string | null,
    role?: Role | null,
    is_default?: boolean | null,
    status?: StatusOrganizationUser | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    userID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    planID: string,
    plan?:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: Status | null,
    pomodoroTime?: number | null,
    shortBreakTime?: number | null,
    longBreakTime?: number | null,
    automaticStartTime?: number | null,
    editionTimeFrame?: string | null,
    created_by?: string | null,
    subscribedSeats?: number | null,
    OrganizationUser?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    planID: string,
    plan?:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: Status | null,
    pomodoroTime?: number | null,
    shortBreakTime?: number | null,
    longBreakTime?: number | null,
    automaticStartTime?: number | null,
    editionTimeFrame?: string | null,
    created_by?: string | null,
    subscribedSeats?: number | null,
    OrganizationUser?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    planID: string,
    plan?:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: Status | null,
    pomodoroTime?: number | null,
    shortBreakTime?: number | null,
    longBreakTime?: number | null,
    automaticStartTime?: number | null,
    editionTimeFrame?: string | null,
    created_by?: string | null,
    subscribedSeats?: number | null,
    OrganizationUser?:  {
      __typename: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename: "OrganizationUser",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        usersub?: string | null,
        role?: Role | null,
        is_default?: boolean | null,
        status?: StatusOrganizationUser | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userID: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Invitations?:  {
      __typename: "ModelInvitationConnection",
      items:  Array< {
        __typename: "Invitation",
        id: string,
        organizationID: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        email?: string | null,
        invitedOn?: string | null,
        invitedBy?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        role?: Role | null,
        status?: StatusInvitation | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskConnection",
      items:  Array< {
        __typename: "Task",
        id: string,
        name?: string | null,
        time?: string | null,
        usersub?: string | null,
        type?: TypeTask | null,
        status?: StatusTask | null,
        hasTimeEdited?: boolean | null,
        projectID?: string | null,
        organizationID: string,
        project?:  {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        TagsTask?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        searchableText?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deleted?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        name: string,
        color?: string | null,
        status?: StatusTag | null,
        organizationID: string,
        Clients?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskTagConnection",
          items:  Array< {
            __typename: "TaskTag",
            id: string,
            tagID: string,
            taskID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePlanSubscriptionVariables = {
  filter?: ModelSubscriptionPlanFilterInput | null,
};

export type OnCreatePlanSubscription = {
  onCreatePlan?:  {
    __typename: "Plan",
    id: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    max_users?: number | null,
    max_projects?: number | null,
    max_clients?: number | null,
    max_groups?: number | null,
    status?: Status | null,
    priceIdStripe?: string | null,
    membership?: TypeMembership | null,
    Organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orders?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePlanSubscriptionVariables = {
  filter?: ModelSubscriptionPlanFilterInput | null,
};

export type OnUpdatePlanSubscription = {
  onUpdatePlan?:  {
    __typename: "Plan",
    id: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    max_users?: number | null,
    max_projects?: number | null,
    max_clients?: number | null,
    max_groups?: number | null,
    status?: Status | null,
    priceIdStripe?: string | null,
    membership?: TypeMembership | null,
    Organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orders?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePlanSubscriptionVariables = {
  filter?: ModelSubscriptionPlanFilterInput | null,
};

export type OnDeletePlanSubscription = {
  onDeletePlan?:  {
    __typename: "Plan",
    id: string,
    name?: string | null,
    description?: string | null,
    price?: number | null,
    max_users?: number | null,
    max_projects?: number | null,
    max_clients?: number | null,
    max_groups?: number | null,
    status?: Status | null,
    priceIdStripe?: string | null,
    membership?: TypeMembership | null,
    Organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        planID: string,
        plan?:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        status?: Status | null,
        pomodoroTime?: number | null,
        shortBreakTime?: number | null,
        longBreakTime?: number | null,
        automaticStartTime?: number | null,
        editionTimeFrame?: string | null,
        created_by?: string | null,
        subscribedSeats?: number | null,
        OrganizationUser?:  {
          __typename: "ModelOrganizationUserConnection",
          items:  Array< {
            __typename: "OrganizationUser",
            id: string,
            organizationID: string,
            email?: string | null,
            usersub?: string | null,
            role?: Role | null,
            is_default?: boolean | null,
            status?: StatusOrganizationUser | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userID: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Invitations?:  {
          __typename: "ModelInvitationConnection",
          items:  Array< {
            __typename: "Invitation",
            id: string,
            organizationID: string,
            email?: string | null,
            invitedOn?: string | null,
            invitedBy?: string | null,
            role?: Role | null,
            status?: StatusInvitation | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Clients?:  {
          __typename: "ModelClientConnection",
          items:  Array< {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tasks?:  {
          __typename: "ModelTaskConnection",
          items:  Array< {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          items:  Array< {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Tags?:  {
          __typename: "ModelTagConnection",
          items:  Array< {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orders?:  {
      __typename: "ModelByOrderConnection",
      items:  Array< {
        __typename: "ByOrder",
        id: string,
        orderID: string,
        planID: string,
        order:  {
          __typename: "Order",
          id: string,
          usersub?: string | null,
          date?: string | null,
          total?: number | null,
          subscriptionId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          plans?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        plan:  {
          __typename: "Plan",
          id: string,
          name?: string | null,
          description?: string | null,
          price?: number | null,
          max_users?: number | null,
          max_projects?: number | null,
          max_clients?: number | null,
          max_groups?: number | null,
          status?: Status | null,
          priceIdStripe?: string | null,
          membership?: TypeMembership | null,
          Organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          orders?:  {
            __typename: "ModelByOrderConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnCreateTagSubscription = {
  onCreateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    color?: string | null,
    status?: StatusTag | null,
    organizationID: string,
    Clients?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnUpdateTagSubscription = {
  onUpdateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    color?: string | null,
    status?: StatusTag | null,
    organizationID: string,
    Clients?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnDeleteTagSubscription = {
  onDeleteTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    color?: string | null,
    status?: StatusTag | null,
    organizationID: string,
    Clients?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Tasks?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnCreateGroupSubscription = {
  onCreateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    status?: Status | null,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UsersGroup?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnUpdateGroupSubscription = {
  onUpdateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    status?: Status | null,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UsersGroup?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnDeleteGroupSubscription = {
  onDeleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    status?: Status | null,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UsersGroup?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        userID: string,
        groupID: string,
        user:  {
          __typename: "User",
          id: string,
          givenName?: string | null,
          familyName?: string | null,
          name?: string | null,
          email?: string | null,
          timeZone?: string | null,
          dateFormat?: string | null,
          timeFormat?: string | null,
          image?: string | null,
          themeMode?: string | null,
          accepTerms?: string | null,
          newUser?: boolean | null,
          customerIdStripe?: string | null,
          disabled?: boolean | null,
          OrganizationUsers?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnCreateClientSubscription = {
  onCreateClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    TagsClient?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status: StatusClient,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnUpdateClientSubscription = {
  onUpdateClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    TagsClient?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status: StatusClient,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnDeleteClientSubscription = {
  onDeleteClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    TagsClient?:  {
      __typename: "ModelClientTagConnection",
      items:  Array< {
        __typename: "ClientTag",
        id: string,
        tagID: string,
        clientID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        client:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status: StatusClient,
    organizationID: string,
    Projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    description?: string | null,
    status: StatusProject,
    groupcolor?: string | null,
    clientID?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    groupID?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    description?: string | null,
    status: StatusProject,
    groupcolor?: string | null,
    clientID?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    groupID?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    description?: string | null,
    status: StatusProject,
    groupcolor?: string | null,
    clientID?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    groupID?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationID: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnCreateTaskSubscription = {
  onCreateTask?:  {
    __typename: "Task",
    id: string,
    name?: string | null,
    time?: string | null,
    usersub?: string | null,
    type?: TypeTask | null,
    status?: StatusTask | null,
    hasTimeEdited?: boolean | null,
    projectID?: string | null,
    organizationID: string,
    project?:  {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    TagsTask?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    searchableText?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deleted?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnUpdateTaskSubscription = {
  onUpdateTask?:  {
    __typename: "Task",
    id: string,
    name?: string | null,
    time?: string | null,
    usersub?: string | null,
    type?: TypeTask | null,
    status?: StatusTask | null,
    hasTimeEdited?: boolean | null,
    projectID?: string | null,
    organizationID: string,
    project?:  {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    TagsTask?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    searchableText?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deleted?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnDeleteTaskSubscription = {
  onDeleteTask?:  {
    __typename: "Task",
    id: string,
    name?: string | null,
    time?: string | null,
    usersub?: string | null,
    type?: TypeTask | null,
    status?: StatusTask | null,
    hasTimeEdited?: boolean | null,
    projectID?: string | null,
    organizationID: string,
    project?:  {
      __typename: "Project",
      id: string,
      name: string,
      description?: string | null,
      status: StatusProject,
      groupcolor?: string | null,
      clientID?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        TagsClient?:  {
          __typename: "ModelClientTagConnection",
          items:  Array< {
            __typename: "ClientTag",
            id: string,
            tagID: string,
            clientID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status: StatusClient,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      groupID?: string | null,
      group?:  {
        __typename: "Group",
        id: string,
        name: string,
        status?: Status | null,
        organizationID: string,
        Projects?:  {
          __typename: "ModelProjectConnection",
          items:  Array< {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UsersGroup?:  {
          __typename: "ModelUserGroupConnection",
          items:  Array< {
            __typename: "UserGroup",
            id: string,
            userID: string,
            groupID: string,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    TagsTask?:  {
      __typename: "ModelTaskTagConnection",
      items:  Array< {
        __typename: "TaskTag",
        id: string,
        tagID: string,
        taskID: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          color?: string | null,
          status?: StatusTag | null,
          organizationID: string,
          Clients?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        task:  {
          __typename: "Task",
          id: string,
          name?: string | null,
          time?: string | null,
          usersub?: string | null,
          type?: TypeTask | null,
          status?: StatusTask | null,
          hasTimeEdited?: boolean | null,
          projectID?: string | null,
          organizationID: string,
          project?:  {
            __typename: "Project",
            id: string,
            name: string,
            description?: string | null,
            status: StatusProject,
            groupcolor?: string | null,
            clientID?: string | null,
            groupID?: string | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          TagsTask?:  {
            __typename: "ModelTaskTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          searchableText?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          deleted?: boolean | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    searchableText?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deleted?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateByOrderSubscriptionVariables = {
  filter?: ModelSubscriptionByOrderFilterInput | null,
};

export type OnCreateByOrderSubscription = {
  onCreateByOrder?:  {
    __typename: "ByOrder",
    id: string,
    orderID: string,
    planID: string,
    order:  {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    plan:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateByOrderSubscriptionVariables = {
  filter?: ModelSubscriptionByOrderFilterInput | null,
};

export type OnUpdateByOrderSubscription = {
  onUpdateByOrder?:  {
    __typename: "ByOrder",
    id: string,
    orderID: string,
    planID: string,
    order:  {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    plan:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteByOrderSubscriptionVariables = {
  filter?: ModelSubscriptionByOrderFilterInput | null,
};

export type OnDeleteByOrderSubscription = {
  onDeleteByOrder?:  {
    __typename: "ByOrder",
    id: string,
    orderID: string,
    planID: string,
    order:  {
      __typename: "Order",
      id: string,
      usersub?: string | null,
      date?: string | null,
      total?: number | null,
      subscriptionId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      plans?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    plan:  {
      __typename: "Plan",
      id: string,
      name?: string | null,
      description?: string | null,
      price?: number | null,
      max_users?: number | null,
      max_projects?: number | null,
      max_clients?: number | null,
      max_groups?: number | null,
      status?: Status | null,
      priceIdStripe?: string | null,
      membership?: TypeMembership | null,
      Organization?:  {
        __typename: "ModelOrganizationConnection",
        items:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          planID: string,
          plan?:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          status?: Status | null,
          pomodoroTime?: number | null,
          shortBreakTime?: number | null,
          longBreakTime?: number | null,
          automaticStartTime?: number | null,
          editionTimeFrame?: string | null,
          created_by?: string | null,
          subscribedSeats?: number | null,
          OrganizationUser?:  {
            __typename: "ModelOrganizationUserConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Invitations?:  {
            __typename: "ModelInvitationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Clients?:  {
            __typename: "ModelClientConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tasks?:  {
            __typename: "ModelTaskConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Groups?:  {
            __typename: "ModelGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          Tags?:  {
            __typename: "ModelTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orders?:  {
        __typename: "ModelByOrderConnection",
        items:  Array< {
          __typename: "ByOrder",
          id: string,
          orderID: string,
          planID: string,
          order:  {
            __typename: "Order",
            id: string,
            usersub?: string | null,
            date?: string | null,
            total?: number | null,
            subscriptionId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          plan:  {
            __typename: "Plan",
            id: string,
            name?: string | null,
            description?: string | null,
            price?: number | null,
            max_users?: number | null,
            max_projects?: number | null,
            max_clients?: number | null,
            max_groups?: number | null,
            status?: Status | null,
            priceIdStripe?: string | null,
            membership?: TypeMembership | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserGroupSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupFilterInput | null,
};

export type OnCreateUserGroupSubscription = {
  onCreateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userID: string,
    groupID: string,
    user:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserGroupSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupFilterInput | null,
};

export type OnUpdateUserGroupSubscription = {
  onUpdateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userID: string,
    groupID: string,
    user:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserGroupSubscriptionVariables = {
  filter?: ModelSubscriptionUserGroupFilterInput | null,
};

export type OnDeleteUserGroupSubscription = {
  onDeleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    userID: string,
    groupID: string,
    user:  {
      __typename: "User",
      id: string,
      givenName?: string | null,
      familyName?: string | null,
      name?: string | null,
      email?: string | null,
      timeZone?: string | null,
      dateFormat?: string | null,
      timeFormat?: string | null,
      image?: string | null,
      themeMode?: string | null,
      accepTerms?: string | null,
      newUser?: boolean | null,
      customerIdStripe?: string | null,
      disabled?: boolean | null,
      OrganizationUsers?:  {
        __typename: "ModelOrganizationUserConnection",
        items:  Array< {
          __typename: "OrganizationUser",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          usersub?: string | null,
          role?: Role | null,
          is_default?: boolean | null,
          status?: StatusOrganizationUser | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Invitations?:  {
        __typename: "ModelInvitationConnection",
        items:  Array< {
          __typename: "Invitation",
          id: string,
          organizationID: string,
          organization?:  {
            __typename: "Organization",
            id: string,
            name?: string | null,
            planID: string,
            status?: Status | null,
            pomodoroTime?: number | null,
            shortBreakTime?: number | null,
            longBreakTime?: number | null,
            automaticStartTime?: number | null,
            editionTimeFrame?: string | null,
            created_by?: string | null,
            subscribedSeats?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          email?: string | null,
          invitedOn?: string | null,
          invitedBy?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          role?: Role | null,
          status?: StatusInvitation | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      status?: Status | null,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UsersGroup?:  {
        __typename: "ModelUserGroupConnection",
        items:  Array< {
          __typename: "UserGroup",
          id: string,
          userID: string,
          groupID: string,
          user:  {
            __typename: "User",
            id: string,
            givenName?: string | null,
            familyName?: string | null,
            name?: string | null,
            email?: string | null,
            timeZone?: string | null,
            dateFormat?: string | null,
            timeFormat?: string | null,
            image?: string | null,
            themeMode?: string | null,
            accepTerms?: string | null,
            newUser?: boolean | null,
            customerIdStripe?: string | null,
            disabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          group:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClientTagSubscriptionVariables = {
  filter?: ModelSubscriptionClientTagFilterInput | null,
};

export type OnCreateClientTagSubscription = {
  onCreateClientTag?:  {
    __typename: "ClientTag",
    id: string,
    tagID: string,
    clientID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    client:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClientTagSubscriptionVariables = {
  filter?: ModelSubscriptionClientTagFilterInput | null,
};

export type OnUpdateClientTagSubscription = {
  onUpdateClientTag?:  {
    __typename: "ClientTag",
    id: string,
    tagID: string,
    clientID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    client:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClientTagSubscriptionVariables = {
  filter?: ModelSubscriptionClientTagFilterInput | null,
};

export type OnDeleteClientTagSubscription = {
  onDeleteClientTag?:  {
    __typename: "ClientTag",
    id: string,
    tagID: string,
    clientID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    client:  {
      __typename: "Client",
      id: string,
      name: string,
      TagsClient?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status: StatusClient,
      organizationID: string,
      Projects?:  {
        __typename: "ModelProjectConnection",
        items:  Array< {
          __typename: "Project",
          id: string,
          name: string,
          description?: string | null,
          status: StatusProject,
          groupcolor?: string | null,
          clientID?: string | null,
          client?:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          groupID?: string | null,
          group?:  {
            __typename: "Group",
            id: string,
            name: string,
            status?: Status | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null,
          organizationID: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTaskTagSubscriptionVariables = {
  filter?: ModelSubscriptionTaskTagFilterInput | null,
};

export type OnCreateTaskTagSubscription = {
  onCreateTaskTag?:  {
    __typename: "TaskTag",
    id: string,
    tagID: string,
    taskID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTaskTagSubscriptionVariables = {
  filter?: ModelSubscriptionTaskTagFilterInput | null,
};

export type OnUpdateTaskTagSubscription = {
  onUpdateTaskTag?:  {
    __typename: "TaskTag",
    id: string,
    tagID: string,
    taskID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTaskTagSubscriptionVariables = {
  filter?: ModelSubscriptionTaskTagFilterInput | null,
};

export type OnDeleteTaskTagSubscription = {
  onDeleteTaskTag?:  {
    __typename: "TaskTag",
    id: string,
    tagID: string,
    taskID: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      color?: string | null,
      status?: StatusTag | null,
      organizationID: string,
      Clients?:  {
        __typename: "ModelClientTagConnection",
        items:  Array< {
          __typename: "ClientTag",
          id: string,
          tagID: string,
          clientID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          client:  {
            __typename: "Client",
            id: string,
            name: string,
            status: StatusClient,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Tasks?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name?: string | null,
      time?: string | null,
      usersub?: string | null,
      type?: TypeTask | null,
      status?: StatusTask | null,
      hasTimeEdited?: boolean | null,
      projectID?: string | null,
      organizationID: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        description?: string | null,
        status: StatusProject,
        groupcolor?: string | null,
        clientID?: string | null,
        client?:  {
          __typename: "Client",
          id: string,
          name: string,
          TagsClient?:  {
            __typename: "ModelClientTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          status: StatusClient,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        groupID?: string | null,
        group?:  {
          __typename: "Group",
          id: string,
          name: string,
          status?: Status | null,
          organizationID: string,
          Projects?:  {
            __typename: "ModelProjectConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          UsersGroup?:  {
            __typename: "ModelUserGroupConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        organizationID: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      TagsTask?:  {
        __typename: "ModelTaskTagConnection",
        items:  Array< {
          __typename: "TaskTag",
          id: string,
          tagID: string,
          taskID: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            color?: string | null,
            status?: StatusTag | null,
            organizationID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          task:  {
            __typename: "Task",
            id: string,
            name?: string | null,
            time?: string | null,
            usersub?: string | null,
            type?: TypeTask | null,
            status?: StatusTask | null,
            hasTimeEdited?: boolean | null,
            projectID?: string | null,
            organizationID: string,
            searchableText?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            deleted?: boolean | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      searchableText?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deleted?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
