import { IProject } from '../../interfaces/project.interface'
import { Role } from '../../models'
import { View, Text, ViewStyle, StyleProp, StyleSheet } from 'react-native'
import { useState, useEffect } from 'react'
import InputText from './InputText'
import ColorPickerNative from './ColorPicker'
import DropdownNative from './MultiSelectNative/MultiSelect'
import GroupedRecordMenu from './GroupedRecordMenu/GroupedRecordMenu'
import useDimensions from '../../hooks/useDimensions'
import { getStyleSheet } from '../../assets/theme/styles'
import { MomentaryView } from './DataTable'

interface IRowProject {
    project: IProject
    allClients: any
    allGroups: any
    userRole: Role
    onUpdateRow: any
    isReadOnly: boolean
    loading?: boolean
    style?: StyleProp<ViewStyle>
}

const RowProject = ({
    project,
    allClients,
    allGroups,
    userRole,
    onUpdateRow,
    isReadOnly,
    style,
    loading,
}: IRowProject) => {
    const isAdmin = userRole === Role.ADMIN
    const [valueName, setValueName] = useState<string>('')
    const { windowWidth } = useDimensions()
    const styles = getStyleSheet()
    const desktopSize = 1200

    const actions = [
        {
            action: () => onUpdateRow(project.id),
            label: isReadOnly ? 'Restore' : 'Archived',
            icon: isReadOnly ? 'restore' : 'archive-arrow-down-outline',
        },
    ]

    useEffect(() => {
        if (project.name !== valueName) setValueName(project.name)
    }, [project])

    const onHandleChangeColor = (color: string, objectId: string, version: number) => {
        const objectUpdate = {
            id: objectId,
            groupcolor: color.replace('#', ''),
            _version: version,
        }
        if (project.groupcolor === color.replace('#', '')) return
        onUpdateRow(null, objectUpdate)
    }

    const onCellEditComplete = ({
        newValue,
        field,
        oldValue,
    }: {
        newValue: string
        field: string
        oldValue: string
    }) => {
        if (newValue.trim().length > 0) {
            if (newValue.trim() !== oldValue.trim()) {
                project[field] = newValue

                const objectUpdate = {
                    id: project['id'],
                    name: newValue,
                    _version: project['_version'],
                }
                onUpdateRow(null, objectUpdate)
            }
        }
    }

    const onHandleChangeClient = (client: any, projectId: string, version: number) => {
        const objectUpdate = {
            id: projectId,
            clientID: client,
            _version: version,
        }

        onUpdateRow(null, objectUpdate)
    }

    const onHandleChangeGroup = (group, projectId, version) => {
        const objectUpdate = {
            id: projectId,
            groupID: group,
            _version: version,
        }

        onUpdateRow(null, objectUpdate)
    }

    return (
        <View
            style={[
                { backgroundColor: styles.backgroundColorRow, padding: 14, gap: 10, borderRadius: 6 },
                windowWidth > desktopSize ? localStylesProject.containerWhenThereOneFile : null,
                style,
            ]}
        >
            <MomentaryView
                showViewUp={desktopSize}
                customStyles={{
                    paddingHorizontal: windowWidth <= 500 ? 10 : 0,
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 20,
                }}
            >
                {!isReadOnly ? (
                    <ColorPickerNative
                        customStyles={{ width: 36 }}
                        value={project.groupcolor}
                        identificator={`${project.id}-color`}
                        loading={loading}
                        skeletonStyles={{ width: 36 }}
                        onChange={color => onHandleChangeColor(color, project.id, project._version)}
                    />
                ) : (
                    <View style={{ width: 36 }} />
                )}

                {isReadOnly ? (
                    <Text style={{ flexGrow: 1, color: styles.text }}>{project.name}</Text>
                ) : (
                    <InputText
                        containerStyle={{ flexGrow: 1 }}
                        disabled={!isAdmin || isReadOnly}
                        loading={loading}
                        value={valueName}
                        skeletonStyles={{ flexGrow: 1, width: 'auto' }}
                        onChange={e => {
                            setValueName(e.valueOf())
                        }}
                        onBlur={() => {
                            onCellEditComplete({
                                field: 'name',
                                newValue: valueName,
                                oldValue: project.name,
                            })
                        }}
                    />
                )}

                {windowWidth <= desktopSize ? (
                    <GroupedRecordMenu
                        buttonTitleStyles={{ paddingHorizontal: 10 }}
                        identificator={project.id}
                        items={actions}
                        loading={loading}
                        skeletonStyles={{ width: 26 }}
                    />
                ) : null}
            </MomentaryView>

            <MomentaryView customStyles={{ flexDirection: 'row', alignItems: 'center' }} showViewUp={desktopSize}>
                {isReadOnly ? (
                    <Text
                        style={{ width: windowWidth <= desktopSize ? '50%' : '20%', color: styles.text, opacity: 0.7 }}
                    >
                        {project.clientName || '- no clients -'}
                    </Text>
                ) : (
                    <DropdownNative
                        containerStyles={{
                            width: windowWidth <= desktopSize ? '50%' : '20%',
                            paddingRight: 5,
                            flexGrow: 0,
                            paddingLeft: windowWidth <= 600 ? 15 : 0,
                        }}
                        customStyles={windowWidth > 600 ? { paddingHorizontal: 4 } : null}
                        loading={loading}
                        skeletonStyles={{ width: windowWidth <= desktopSize ? '50%' : '20%', marginRight: 5 }}
                        valueWhenUniqueSelection={project.clientID}
                        indentificator={`${project.id}-client`}
                        options={allClients as any}
                        onChange={change => onHandleChangeClient(change, project.id, project._version)}
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Client"
                        uniqueSelection
                        emptyFilterMessage="No Client added"
                        disabled={!isAdmin || isReadOnly}
                        includeHovered
                    />
                )}

                {isReadOnly ? (
                    <Text
                        style={{ width: windowWidth <= desktopSize ? '50%' : '20%', color: styles.text, opacity: 0.7 }}
                    >
                        {project.clientName || '- no groups -'}
                    </Text>
                ) : (
                    <DropdownNative
                        containerStyles={{
                            width: windowWidth <= desktopSize ? '50%' : '20%',
                            paddingLeft: 5,
                            flexGrow: 0,
                        }}
                        customStyles={windowWidth > 600 ? { paddingLeft: 4, paddingRight: 4 } : null}
                        loading={loading}
                        skeletonStyles={{ width: windowWidth <= desktopSize ? '50%' : '20%' }}
                        valueWhenUniqueSelection={project.groupID}
                        indentificator={`${project.id}-group`}
                        options={allGroups}
                        onChange={change => onHandleChangeGroup(change, project.id, project._version)}
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Group"
                        uniqueSelection
                        emptyFilterMessage="No groups added"
                        disabled={!isAdmin || isReadOnly}
                        includeHovered
                    />
                )}
            </MomentaryView>

            {windowWidth > desktopSize ? (
                <GroupedRecordMenu
                    loading={loading}
                    skeletonStyles={{ width: 26 }}
                    identificator={project.id}
                    items={actions}
                />
            ) : null}
        </View>
    )
}

export const localStylesProject = StyleSheet.create({
    containerWhenThereOneFile: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        padding: 0,
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
})

export default RowProject
