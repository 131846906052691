import { generateClient } from 'aws-amplify/api'
import { createContext, useEffect, useContext } from 'react'
import { TypeTask } from '../API'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { createTask, createTaskTag, deleteTask, updateTask } from '../graphql/mutations'
import { getTask, listTasks } from '../graphql/queries'
import {
    formatTasksForTable,
    validationEditionTimeFrame,
    handleRecalculateDates,
    secondsToString,
} from '../helpers/utils'
import { IGraphqlOptions } from '../interfaces/graphql-options'
import { ITaskContinue } from '../interfaces/task-continue.interface'
import { ITaskProvider } from '../interfaces/task-provider.interface'
import { ITask } from '../interfaces/task.interface'
import { TaskService } from '../services/TaskService'
import { useUserProvider } from './UserProvider'
import { StatusTask } from '../models'
import useState from 'react-usestateref'
import moment from 'moment'
import { TagsService } from '../services/TagsService'

const TaskContext = createContext<ITaskProvider>(null as any)

function TaskProvider({ children }) {
    const [continueTask, setContinueTask] = useState<ITaskContinue>()
    const [localTasksGrouped, setLocalTasksGrouped, localTasksGroupedRef] = useState<any[]>([])
    const [localTasks, setLocalTasks] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const { editionTimeFrame, pomodoroTime } = useContext<any>(ThemeContext)
    const { timeFormat, userActiveOrganization } = useUserProvider()

    const getFilteredTask = async (filters: IGraphqlOptions) => {
        const tasksResponse: any = await generateClient().graphql({ query: listTasks, variables: filters })
        const tasks = tasksResponse.data.listTasks.items.filter(t => !t._deleted)
        return tasks
    }

    const playTask = (playTask: ITaskContinue) => {
        setContinueTask(playTask)
    }

    const getAllTaskByUser = async (userSub: string, organizationID: string, dateRange: any, reset?: boolean) => {
        setLoading(true)

        const response: any = await generateClient().graphql({
            query: listTasks,
            variables: {
                limit: 2000,
                filter: {
                    usersub: { eq: userSub },
                    organizationID: { eq: organizationID },
                    createdAt: { between: dateRange },
                },
            },
        })

        const result = formatTasksForTable(response?.data?.listTasks?.items, timeFormat, false, pomodoroTime)

        if (reset) {
            setLocalTasksGrouped([...result])
        } else {
            setLocalTasksGrouped([...localTasksGroupedRef.current, ...result])
        }

        setLoading(false)
        return result
    }

    const getAllTaskByUserNoLoading = async (task: ITask[]) => {
        const result = formatTasksForTable(task, timeFormat, false, pomodoroTime)
        setLocalTasksGrouped([...localTasksGrouped, ...result])
    }

    useEffect(() => {
        const result = []

        const sortedTasks = localTasksGroupedRef.current.sort((a, b) => {
            return moment(b.createdTypeDate).diff(a.createdTypeDate)
        })

        sortedTasks.reduce(function (res, value) {
            if (!value?.createdDateFormat) return res
            if (!res[value.createdDateFormat]) {
                res[value.createdDateFormat] = { Id: value.createdDateFormat, totalTime: 0, tasks: [] }
                result.push(res[value.createdDateFormat] as never)
            }

            res[value.createdDateFormat].totalTime += moment.duration(value.totalTime)
            res[value.createdDateFormat].tasks.push(value)
            return res
        }, {})

        setLocalTasks(result)
    }, [localTasksGroupedRef?.current])

    const controlEditionTimeFrame = (objectId: string) => {
        let findId = ''
        if (objectId.includes(',')) {
            const arrayIds = objectId.split(',')
            findId = arrayIds[0]
        } else {
            findId = objectId
        }

        const row = localTasksGroupedRef.current.find(item => item.id.includes(findId))
        if (row) {
            const task = row.groupTask.find(item => item.id === findId)
            if (task) {
                return validationEditionTimeFrame(editionTimeFrame, task.created)
            }
        }
        return true
    }

    const onlySaveTask = async (taskActive, status: string, selectedTag): Promise<ITask> => {
        const task = taskActive
        task.status = status
        task.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ssZ')

        const tagsSelected = selectedTag
        let resTask = await createNewTask(task)

        if (resTask.id && tagsSelected.length > 0) {
            for (const tagTask of tagsSelected as any) {
                const tagsTask = { taskID: resTask.id, tagID: tagTask.value }
                const tagData: any = await TaskService().createTagsInTask(tagsTask)
                resTask = tagData?.data?.createTaskTag?.task
            }
        }

        return resTask
    }

    const duplicateTask = (task: any, selectedTags: any) => {
        const newTask: any = {
            name: task.name,
            usersub: userActiveOrganization?.usersub,
            organizationID: userActiveOrganization?.organizationID || '',
            type: TypeTask.FOCUS,
            createdAt: task.createdAt,
            time: task.time,
            hasTimeEdited: task.hasTimeEdited,
            projectID: task.projectID,
        }

        saveTask(newTask, StatusTask.FINISHED, selectedTags)
    }

    const saveTask = async (taskActive: ITask, status: string, selectedTag) => {
        const task = taskActive

        //Eliminar
        if (task?.limitTime) {
            delete task.limitTime
        }

        if (task?.countDown) {
            delete task.countDown
        }

        task.status = status
        task.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ssZ')

        let resTask = await createNewTask(task)

        const newTags: any[] = [] // Variable que guardará los tasks no creados

        const areThereTagsCreate = selectedTag
            .filter((tag: any) => !tag.value) // Filtrando los tags que no estan creados
            .map((tag: any) => {
                const newTag = {
                    color: '808080',
                    name: tag.label,
                    organizationID: userActiveOrganization?.organizationID,
                    status: 'ACTIVE',
                }
                return newTag
            })

        if (areThereTagsCreate.length) {
            for (const tag of areThereTagsCreate) {
                const newTag = await TagsService().saveTag(tag) // Creando los tags no creados
                newTags.push(newTag)
            }
        }

        const dataToSend = newTags.length // Todos los tags ya creados
            ? selectedTag.map((tag: any) => {
                  const findTag = newTags.find(newTag => newTag.name === tag.label)
                  if (findTag) return { color: findTag.color, label: findTag.name, value: findTag.id }
                  return tag
              })
            : selectedTag

        if (resTask.id && dataToSend.length > 0) {
            for (const tagTask of dataToSend as any) {
                const tagsTask = { taskID: resTask.id, tagID: tagTask.value }
                const tagData: any = await TaskService().createTagsInTask(tagsTask)
                resTask = tagData?.data?.createTaskTag?.task
            }
        }

        const result = formatTasksForTable([resTask], timeFormat, false, pomodoroTime)[0]

        if (result) {
            const key = result.name + '-' + result.projectID + '-' + result.TagsName + '-' + result.createdDateFormat

            const localTasksGroupedArr = [...localTasksGroupedRef.current]
            let groupedTask = false

            for (let index = 0; index < localTasksGroupedArr.length; index++) {
                const eleTask = localTasksGroupedArr[index]
                const taskKey =
                    eleTask.name + '-' + eleTask.projectID + '-' + eleTask.TagsName + '-' + eleTask.createdDateFormat

                if (taskKey === key) {
                    eleTask.pomodoro += result.pomodoro
                    eleTask.totalTime =
                        result.type === TypeTask.FOCUS
                            ? moment
                                  .utc(moment.duration(result.billedTime).add(eleTask.totalTime).asMilliseconds())
                                  .format('HH:mm:ss')
                            : moment
                                  .utc(moment.duration('00:00:00').add(eleTask.totalTime).asMilliseconds())
                                  .format('HH:mm:ss')

                    eleTask.createdTimeFormat = result.createdTimeFormat
                    result.parentId = eleTask.id
                    eleTask.groupTask.unshift(result)
                    eleTask.id = eleTask.id.concat(',', result.id)
                    groupedTask = true
                    break
                }
            }

            if (!groupedTask) {
                localTasksGroupedArr.push(result)
            }

            setLocalTasksGrouped([...localTasksGroupedArr])
        }
    }

    const createNewTask = async (task: ITask) => {
        try {
            const response: any = await generateClient().graphql({
                query: createTask,
                variables: {
                    input: {
                        ...task,
                        searchableText: task?.name.toLowerCase(),
                    },
                },
            })
            return response?.data?.createTask
        } catch (error) {
            console.log(error)
        }
    }

    const getTaskById = async taskId => {
        try {
            const response: any = await generateClient().graphql({ query: getTask, variables: { id: taskId } })
            return response?.data?.getTask
        } catch (error) {
            return
        }
    }

    const onUpdateRow = async (newObject: any, isSortedEndDate?: boolean) => {
        if (controlEditionTimeFrame(newObject.id)) return
        if (newObject.id.includes(',')) {
            const arrayIds = newObject.id.split(',')
            const updatedTasks = await updateMultipleTask(arrayIds, newObject)
            const formatedTask = formatTasksForTable(updatedTasks, timeFormat, false, pomodoroTime)[0]
            setLocalTasksGrouped([...localTasksGrouped.filter(task => task.id !== formatedTask.id), formatedTask])
        } else {
            await updateSingleTask(newObject)
            // const data = await updateSingleTask(newObject)
            // if (data) {
            //     const newData = isSortedEndDate ? handleRecalculateDates(localTasksGroupedRef.current, newObject) : data
            //     setLocalTasksGrouped(newData)
            // }
        }
    }

    const updateMultipleTask = async (arrayIds, updateObject) => {
        let tasks: any[] = []
        for (const id of arrayIds) {
            const row = localTasksGroupedRef.current.find(item => item.id.includes(id))
            if (row) {
                const task = row.groupTask.find(item => item.id === id)
                if (task) {
                    let newDate = moment(updateObject.createdAt).format('YYYY-MM-DD')
                    let splitDateTime = task.created.split('T')
                    let elementDate = splitDateTime[0]
                    let elementTime = splitDateTime[1]
                    let newCreatedDate = newDate + 'T' + elementTime

                    let objectUpdate = {
                        id: task.id,
                        name: updateObject['name'],
                        projectID: updateObject['projectID'],
                        createdAt: newCreatedDate,
                        hasTimeEdited: updateObject['hasTimeEdited'],
                        _version: task._version,
                    }

                    const updatedTask = await updateSingleTask(objectUpdate, true, localTasksGroupedRef.current)
                    tasks.push(updatedTask)
                }
            }
        }
        return tasks
    }

    const updateSingleTask = async (updateObject, multipleUpdate?: boolean, tasks?: any[]) => {
        if (updateObject?.name) {
            updateObject['searchableText'] = updateObject.name.toLowerCase()
        }

        const responseData: any = await generateClient().graphql({
            query: updateTask,
            variables: { input: updateObject },
        })

        if (multipleUpdate) {
            return responseData?.data?.updateTask
        }

        // let taskUpdatedIndex = localTasksGroupedRef.current.findIndex(ele => ele.id === taskData.id)
        // const key =
        //     taskData.name + '-' + taskData.projectID + '-' + taskData.TagsName + '-' + taskData.createdDateFormat

        // let tasksTmp = tasks ? [...tasks] : [...localTasksGroupedRef.current]
        // let tasksTmpBck = [...localTasksGroupedRef.current]

        // let taskGrouped = false
        // let newTotalTime: any = 0

        // if (taskUpdatedIndex === -1) {
        //     const parentIndex = tasksTmp.findIndex(ele => ele.id.includes(taskData.id))
        //     const childIndex = tasksTmp[parentIndex].groupTask.findIndex(i => i.id === taskData.id)

        //     const billedTimeOld = tasksTmpBck[parentIndex].groupTask[childIndex].time

        //     newTotalTime = +moment.duration(tasksTmp[parentIndex].totalTime) - +moment.duration(billedTimeOld)

        //     tasksTmp[parentIndex]?.groupTask?.splice(childIndex, 1, taskData)

        //     newTotalTime += moment.duration(taskData.billedTime)
        //     newTotalTime = secondsToString(newTotalTime)
        // }

        // for (let index = 0; index < tasksTmp.length; index++) {
        //     const eleTask = tasksTmp[index]
        //     const taskKey =
        //         eleTask.name + '-' + eleTask.projectID + '-' + eleTask.TagsName + '-' + eleTask.createdDateFormat

        //     if (taskKey === key && taskUpdatedIndex !== index) {
        //         if (taskUpdatedIndex === -1) {
        //             taskUpdatedIndex = index - 1
        //         }

        //         if (eleTask.groupTask?.length === eleTask.pomodoro) {
        //             if (taskData.status === StatusTask.FINISHED && taskData.hasTimeEdited) {
        //                 if (taskData.pomodoro === 0) {
        //                     eleTask.pomodoro -= 1
        //                 }
        //             }
        //         } else {
        //             eleTask.pomodoro += taskData.status === StatusTask.FINISHED ? taskData.pomodoro : 0
        //         }

        //         eleTask.totalTime = newTotalTime
        //         taskData.parentId = eleTask.id
        //         taskGrouped = true
        //         break
        //     }
        // }

        // if (taskGrouped) {
        // } else {
        //     if (taskUpdatedIndex !== -1) {
        //         tasksTmp[taskUpdatedIndex] = taskData
        //     }
        // }

        // return tasksTmp
    }

    const onDeleteRow = async (currentDelete: any) => {
        if (!currentDelete) {
            return
        }

        if (currentDelete.includes(',')) {
            const arrayIds = currentDelete.split(',')
            await deleteMultipleTask(arrayIds, currentDelete)
            return
        }

        await deleteSingleTask(currentDelete)
    }

    const deleteSingleTask = async (id: string) => {
        const row = localTasksGroupedRef.current.find(item => item.id.includes(id))

        if (!row) {
            return
        }

        const task = row.groupTask.find(item => item.id === id)
        if (!task) {
            return
        }

        await deleteTaskById(task.id, task._version)

        let tasksTmp = localTasksGroupedRef.current.filter(ele => ele.id !== task.id)

        const singleTask: any[] = []

        for (let index = 0; index < tasksTmp.length; index++) {
            const element = tasksTmp[index]

            if (element.groupTask.length > 1) {
                element.groupTask.forEach(taskEle => {
                    if (task.id !== taskEle.id) {
                        singleTask.push(taskEle)
                    }
                })
            } else {
                singleTask.push(element)
            }
        }

        setLocalTasksGrouped(formatTasksForTable(singleTask, timeFormat, false, pomodoroTime))
    }

    const deleteTaskFromMultipleTasks = async (id: string) => {
        const row = localTasksGroupedRef.current.find(item => item.id.includes(id))

        if (!row) {
            return
        }

        const task = row.groupTask.find(item => item.id === id)
        if (!task) {
            return
        }

        await deleteTaskById(task.id, task._version)
    }

    const deleteGroupedTasks = async (groupedTasks: any) => {
        const txnMutation: any = groupedTasks.map((item, i) => {
            return `mutation${i}: deleteTask(input: {id: "${item.id}", _version: ${item._version}}) { id }`
        })

        await generateClient().graphql({ query: `mutation batchMutation {${txnMutation}}` })
    }

    const deleteMultipleTask = async (arrayIds, allTasksIds) => {
        let foundGrouped = localTasksGroupedRef.current.find(ele => ele.id === allTasksIds)
        if (!foundGrouped) {
            return
        }

        await deleteGroupedTasks(foundGrouped.groupTask)
        let tasksTmp = localTasksGroupedRef.current.filter(ele => ele.id !== allTasksIds)

        setLocalTasksGrouped([...tasksTmp])
    }

    const deleteTaskById = async (id: string, version) => {
        const responseData: any = await generateClient().graphql({
            query: deleteTask,
            variables: { input: { id: id, _version: version } },
        })
        const taskData = responseData?.data?.deleteTask

        return taskData
    }

    const deleteAllTagsInTask = async (tags: any) => {
        const txnMutation: any = tags.map((txn, i) => {
            return `mutation${i}: deleteTaskTag(input: {id: "${txn.id}", _version: ${txn._version}}) { id }`
        })

        const responseData: any = await generateClient().graphql({ query: `mutation batchMutation {${txnMutation}}` })
    }

    const changeTag = async (selectedTags: any, task: any) => {
        if (task.id.includes(',')) {
            const arrayIds = task.id.split(',')
            task.groupTask.map(async item => {
                if (item.TagsTask.items.length > 0) {
                    await deleteAllTagsInTask(item.TagsTask.items)
                }
            })

            await addMultipleTagsTask(arrayIds, selectedTags)
        } else {
            if (task.TagsTask.items.length > 0) {
                await deleteAllTagsInTask(task.TagsTask.items)
            }

            if (selectedTags.length > 0) {
                let taskData: any = {}

                for (const tagTask of selectedTags) {
                    const tagsTask = { taskID: task.id, tagID: tagTask.value }
                    const taskDataTmp = await createTagsInTask(tagsTask)
                    taskData = taskDataTmp
                }

                const tasksTmp = localTasksGroupedRef.current.map(ele => {
                    if (ele.id === taskData.id) {
                        return formatTasksForTable([taskData], timeFormat, false, pomodoroTime)[0]
                    } else {
                        return ele
                    }
                })

                setLocalTasksGrouped(tasksTmp)
            }
        }
    }

    const createTagsInTask = async (tagsTask: object) => {
        const responseData: any = await generateClient().graphql({
            query: createTaskTag,
            variables: { input: tagsTask },
        })
        const taskData = responseData?.data?.createTaskTag.task

        return taskData
    }

    const addMultipleTagsTask = async (arrayIds, selectedTags) => {
        for (const taskId of arrayIds) {
            for (const tagTask of selectedTags) {
                const tagsTask = { taskID: taskId, tagID: tagTask.value }
                await createTagsInTask(tagsTask)
            }
        }
    }

    return (
        <TaskContext.Provider
            value={{
                continueTask,
                localTasksGrouped: localTasksGroupedRef?.current,
                localTasks,
                loading,
                duplicateTask,
                setContinueTask,
                getFilteredTask,
                playTask,
                getAllTaskByUser,
                onUpdateRow,
                saveTask,
                onDeleteRow,
                updateSingleTask,
                deleteAllTagsInTask,
                changeTag,
                createTagsInTask,
                onlySaveTask,
                getAllTaskByUserNoLoading,
            }}
        >
            {children}
        </TaskContext.Provider>
    )
}

function useTaskProvider() {
    const context = useContext(TaskContext)
    if (context === undefined) {
        throw new Error('useTaskProvider must be used within a TaskProvider')
    }
    return context
}

export { TaskProvider, useTaskProvider }
