import { generateClient } from 'aws-amplify/api'
import { listTasks } from '../graphql/queries'
import { useDateFormat } from '../hooks/useDateFormat'
import { OrganizationService } from './OrganizationService'
import { ClientService } from './ClientService'
import { StatusTask, TypeTask } from '../models'
import { uniqBy } from 'lodash'
import moment from 'moment'
import { TaskService } from './TaskService'
import { secondsToString, transformNumberToTime } from '../helpers/utils'
import { ITaskByDateRange } from '../interfaces/task.interface'
import { Dimensions, Platform } from 'react-native'
import { getReportPdfMutation } from '../graphql/mutations'
import { createFileOnMobile } from '../hooks/handleMobileDownload'
import { ReportDetailService, ItemTasksByProject } from './ReportDetailService'

export interface IGroupedData {
    id: string
    date: string
    clientName: string
    projectName: string
    projectColor: string
    name: string
    pomodoroTime: number
    createdOnlyDate: string
    breakTime: number
    duration: any
    durationbreak: any
    durationFormat: string
    durationbreakFormat: string
    totalTasks: number
    tasks: Array<any>
    groupedTasks: Array<any>
}

const ReportService = () => {
    const headersExportCsv = [
        { label: 'Title', key: 'name' },
        { label: 'Tasks', key: 'tasks' },
        { label: 'Duration', key: 'duration' },
    ]

    const progressCircleInit = () => {
        return {
            progress: 0,
            progressFormat: '0',
            total: 0,
            totalFormat: '0',
            pomodoros: 0,
            sleep: 0,
            sleepFormat: '0',
            pomodorosEditedPercent: 0,
        }
    }

    const groupByItems = () => {
        return [
            { name: 'Project', code: 'project' },
            { name: 'Client', code: 'client' },
        ]
    }

    const getAllTask = async (dateRange: any, userSub: string) => {
        try {
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)

            const allTasks: any = await generateClient().graphql({
                query: listTasks,
                variables: {
                    limit: 2000,
                    filter: { organizationID: { eq: organizationId }, createdAt: { between: dateRange } },
                },
            })

            return allTasks?.data?.listTasks?.items
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getTasksByRangeDate = async (datesRange: any, userSub: string) => {
        try {
            let allTask = await getAllTask(datesRange, userSub)
            console.log({ allTask })

            let list = await Promise.all(
                allTask
                    ?.filter(t => !t._deleted)
                    .map(async item => {
                        let clientObject = await ClientService().getClientById(item.project?.clientID)
                        return {
                            name: item.name,
                            pomodoroTime: item.pomodoroTime || 0,
                            time: item.time,
                            createdDate: item.createdAt,
                            createdTypeDate: moment(item.createdAt),
                            createdDateFormat: new Date(item.createdAt),
                            axisX: useDateFormat().formatLocaleMonthDay(item.createdAt),
                            userSub: item.usersub,
                            projectID: item.project?.id,
                            projectColor: item.project?.groupcolor,
                            projectName: item.project?.name ?? '',
                            clientId: item.project?.clientID,
                            clientName: clientObject ? clientObject[0]?.name : '',
                            type: item.type,
                            status: item.status,
                            organizationID: item.organizationID,
                            hasTimeEdited: item.hasTimeEdited,
                        }
                    })
            )

            list = list.sort((a, b) => {
                return moment(b.createdTypeDate).diff(a.createdTypeDate)
            })

            return list
        } catch (error) {
            console.log(error)
            return []
        }
    }

    interface ITaskGroupedBy {
        axisX: string
        hours: number
        minutes: number
        pomodoroTime: number
    }

    const getTasksByWeek = (tasks: ITaskByDateRange[], initDate: Date, endDate: Date): ITaskGroupedBy[] => {
        const diffDays = Math.abs(moment(initDate).diff(moment(endDate), 'day'))
        const newDate = new Date(initDate.getFullYear(), initDate.getMonth(), initDate.getDate())
        let tasksByWeeks = {} // { month: { OCt w3: moment() } }

        for (let i = 0; i < diffDays; i++) {
            const currentDate = moment(newDate)
            const currentWeek = Math.ceil(currentDate.date() / 7)
            const monthLabel = currentDate.format('MMM')
            const weekLabel = `${monthLabel} w${currentWeek}`

            tasksByWeeks[monthLabel] = {
                ...tasksByWeeks[monthLabel],
                [weekLabel]: { hours: 0, minutes: 0, weekLabel, pomodoroTime: 0 },
            }

            newDate.setDate(newDate.getDate() + 1)
        }

        tasks.forEach(task => {
            const currentDate = moment(task.createdDate)
            const currentWeek = Math.ceil(currentDate.date() / 7)
            const monthLabel = currentDate.format('MMM')
            const weekLabel = `${monthLabel} w${currentWeek}`

            const [hours, minutes] = task.time.split(':')

            if (tasksByWeeks[monthLabel][weekLabel]) {
                tasksByWeeks[monthLabel][weekLabel] = {
                    hours: (tasksByWeeks[monthLabel][weekLabel].hours += +hours),
                    minutes: (tasksByWeeks[monthLabel][weekLabel].minutes += +minutes),
                    pomodoroTime: (tasksByWeeks[monthLabel][weekLabel].pomodoroTime += +task.pomodoroTime),
                    weekLabel,
                }
            }
        })

        return Object.entries(tasksByWeeks)
            .map(([_label, weeksByMonth]: any) => {
                return Object.values(weeksByMonth).map((week: any) => {
                    return {
                        axisX: week.weekLabel,
                        hours: week.hours,
                        minutes: week.minutes,
                        pomodoroTime: week.pomodoroTime,
                    }
                })
            })
            .flat()
    }

    const getTasksByDay = (tasks: ITaskByDateRange[], initDate: Date, endDate: Date): ITaskGroupedBy[] => {
        const diffDays = Math.abs(moment(initDate).diff(moment(endDate), 'days'))
        const newDate = new Date(initDate.getFullYear(), initDate.getMonth(), initDate.getDate())
        let allTasksByRangeDate = {}

        for (let i = 0; i <= diffDays; i++) {
            const currentDate = moment(newDate)
            const objectLabel = `${currentDate.format('MMM')} ${currentDate.format('D')}`
            allTasksByRangeDate = {
                ...allTasksByRangeDate,
                [objectLabel]: [{ time: '00:00:00', createdDateFormat: currentDate, pomodoroTime: 0 }],
            }
            newDate.setDate(newDate.getDate() + 1)
        }

        const groupedTasksByDays = tasks.reduce((acc: any, item: ITaskByDateRange) => {
            const currentDate = moment(item.createdDateFormat)
            const objectLabel = `${currentDate.format('MMM')} ${currentDate.format('D')}`

            if (acc[objectLabel] && Array.isArray(acc[objectLabel])) {
                acc[objectLabel].push(item)
                return acc
            }

            acc[objectLabel] = [item]
            return acc
        }, {})

        return Object.entries({ ...allTasksByRangeDate, ...groupedTasksByDays }).map(([day, tasks]) => {
            const newTasks = tasks as ITaskByDateRange[]

            const { hours, minutes, pomodoroTime } = newTasks.reduce(
                (acc, task) => {
                    const [hours, minutes, _seconds] = task.time.split(':')
                    acc.hours += +hours
                    acc.minutes += +minutes
                    acc.pomodoroTime += task.pomodoroTime
                    return acc
                },
                { hours: 0, minutes: 0, pomodoroTime: 0 }
            )

            return { axisX: day, hours, minutes, pomodoroTime }
        })
    }

    const groupTaskByColor = (pomodoroTime: number, tasksByPeriods: ITaskGroupedBy[]) => {
        const [hours, minutes, _seconds] = transformNumberToTime(pomodoroTime).split(':')
        const pomodoroTimeToMinutes = +hours * 60 + +minutes

        return tasksByPeriods.map(task => {
            const minutes = task.hours * 60 + task.minutes
            const requiredPomodoro = Math.round(minutes / pomodoroTimeToMinutes)

            const calc = Math.round((task.pomodoroTime * 100) / requiredPomodoro || 0)

            if (calc >= 90) {
                return { ...task, axisY: task.hours, axisX: task.axisX, color: '#3dc86e', pomodoroPorcentage: calc }
            }

            if (calc >= 70 && calc < 90) {
                return { ...task, axisY: task.hours, axisX: task.axisX, color: '#ffac33', pomodoroPorcentage: calc }
            }

            return { ...task, axisY: task.hours, axisX: task.axisX, color: '#ff735a', pomodoroPorcentage: calc }
        })
    }

    const getDataChart = async (list: ITaskByDateRange[], initDate: Date, endDate: Date, pomodoroTime: number) => {
        try {
            // De un width de 1,000px para abajo se calculará si el rango de fecha está en la misma semana
            if (Dimensions.get('window').width <= 1000) {
                if (moment(endDate).week() === moment(initDate).week()) {
                    // Si el rango de fecha está en la misma semana el reporte se muestra en base a los días de esa semana
                    const data = getTasksByDay(list, initDate, endDate)
                    return groupTaskByColor(pomodoroTime, data)
                }

                // Si el rango de fecha supera una semana, el reporte se muestra semanalmente
                const data = getTasksByWeek(list, initDate, endDate)
                return groupTaskByColor(pomodoroTime, data)
            }

            // Si es mayor a 1,000px se mostrará día por día
            const data = getTasksByDay(list, initDate, endDate)
            return groupTaskByColor(pomodoroTime, data)
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    const getDataProgressBar = (array: Array<any>) => {
        let pomodoro: number = 0
        let pomodorosEdited: number = 0
        let total = moment.duration('00:00:00')
        let totalProgress = moment.duration('00:00:00')
        let totalSleep = moment.duration('00:00:00')

        array.map(item => {
            pomodoro += item.pomodoroTime
            pomodorosEdited += item.pomodoroTimeEdited
            ;(total = total.add(item.duration)),
                (totalProgress = totalProgress.add(item.duration)),
                (totalSleep = totalSleep.add(item.durationbreak))
        })

        total = total.add(totalSleep)

        return {
            pomodoros: pomodoro || 0,
            total: total.asMinutes() || 0,
            totalFormat: secondsToString(total.asMilliseconds()) || '',
            progress: totalProgress.asMinutes() || 0,
            progressFormat: secondsToString(totalProgress.asMilliseconds()) || '',
            sleep: totalSleep.asMinutes() || 0,
            sleepFormat: secondsToString(totalSleep.asMilliseconds()) || '',
            pomodorosEditedPercent: (pomodorosEdited * 100) / pomodoro > 100 ? 100 : (pomodorosEdited * 100) / pomodoro,
        }
    }

    const getGroupedDatatable = (tasks: Array<any>, groupedSelect: any) => {
        if (!tasks.length) return []

        let tasksGrouping = new Array<IGroupedData>()
        for (const task of tasks) {
            let groupFound: any
            let groupName: string

            if (groupedSelect === 'client') {
                groupFound = tasksGrouping.find(e => e.name === task.clientName)
                groupName = task.clientName
            } else {
                groupFound = tasksGrouping.find(e => e.name === task.projectName)
                groupName = task.projectName
            }
            if (groupFound) {
                groupFound.duration =
                    task.type === TypeTask.FOCUS
                        ? moment.duration(task.time).add(groupFound.duration)
                        : moment.duration('00:00:00').add(groupFound.duration)
                groupFound.durationbreak =
                    task.type !== TypeTask.FOCUS
                        ? moment.duration(task.time).add(groupFound.durationbreak)
                        : moment.duration('00:00:00').add(groupFound.durationbreak)
                groupFound.tasks.push(task)
            } else {
                const newGroup: IGroupedData = {
                    id: task.id,
                    clientName: task.clientName,
                    date: task.createdDateFormat,
                    createdOnlyDate: moment(task.createdDateFormat).format('YYYY-MM-DD'),
                    projectColor: task.projectColor,
                    projectName: task.projectName,
                    name: groupName,
                    pomodoroTime: 0,
                    breakTime: 0,
                    duration: task.type === TypeTask.FOCUS ? moment.duration(task.time) : moment.duration('00:00:00'),
                    durationbreak:
                        task.type !== TypeTask.FOCUS ? moment.duration(task.time) : moment.duration('00:00:00'),
                    durationFormat: '',
                    durationbreakFormat: '',
                    totalTasks: 0,
                    tasks: new Array<any>(),
                    groupedTasks: new Array<any>(),
                }

                newGroup.tasks.push(task)
                tasksGrouping.push(newGroup)
            }
        }

        tasksGrouping = tasksGrouping.map(p => {
            return {
                ...p,
                name: p.name?.length ? p.name : '-Tasks with no projects/no clients-',
                durationFormat: moment.utc(p.duration.asMilliseconds()).format('HH:mm:ss'),
                createdDateFormat: moment(p.date, 'YYYY MM DD hh:mm:ss Z').format('MMM DD, YYYY'),
                durationbreakFormat: moment.utc(p.durationbreak.asMilliseconds()).format('HH:mm:ss'),
                pomodoroTime: p.tasks.filter(e => e.type === TypeTask.FOCUS && e.status === StatusTask.COMPLETED)
                    .length,
                tasks:
                    p.tasks.length <= 1 // Si solo hay una tarea esta misma se renderiza 2 veces, por eso si hay 1 devuelvo el [] vacio
                        ? []
                        : p.tasks,
                pomodoroTimeEdited: p.tasks.filter(e => e.type === TypeTask.FOCUS && e.hasTimeEdited === true).length,
                breakTime: p.tasks.filter(e => e.type !== TypeTask.FOCUS).length,
                totalTasks: uniqBy(p.tasks, 'name').length,
                groupedTasks: TaskService().groupTasks(p.tasks),
            }
        })
        return tasksGrouping
    }

    const transformDataToExport = groupedTasks => {
        return ReportDetailService().transformDataToExport(
            groupedTasks.map(item => ({
                ...item,
                createdDateFormat: moment(item.date, 'YYYY MM DD hh:mm:ss Z').format('MMM DD, YYYY'),
            }))
        )
    }

    const handleWebDownload = (data: string) => {
        const pdfBlob = new Blob([Uint8Array.from(atob(data), c => c.charCodeAt(0))], {
            type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(pdfBlob)
        link.download = `Report_detailed_${moment().format('MM/DD/YYYY')}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const exportSummaryPdf = async (exportData: any, dateRange: any, totalTime: any, workSpaceName: string) => {
        const parserData = ReportDetailService().parserDataForPdfChart(
            exportData,
            new Date(dateRange[0]),
            new Date(dateRange[1])
        )
        if (!parserData?.length) return

        const tasksGroupedByProjects = ReportDetailService().getTasksGroupedByProjects(exportData)
        const generalChartImage = ReportDetailService().generateGeneralChart(parserData)
        const imageProjectChart = ReportDetailService().generateProjectChart(
            tasksGroupedByProjects as unknown as ItemTasksByProject[],
            totalTime
        )

        const imageTasksChart = ReportDetailService().generateTasksChart(exportData, totalTime)

        const request: any = await generateClient().graphql({
            query: getReportPdfMutation,
            variables: {
                input: {
                    reportData: exportData,
                    tasksByProjects: tasksGroupedByProjects as any,
                    dateRange,
                    totalTime: `${totalTime}h`,
                    workSpaceName,
                    headerTitle: 'Detailed report',
                    isDetailed: true,
                    imageGeneralReport: generalChartImage,
                    imageProjectChart,
                    imageTasksChart,
                },
            },
        })

        const data = request?.data?.getReportPdfMutation

        if (Platform.OS === 'web') {
            handleWebDownload(data)
        } else
            createFileOnMobile(
                data,
                `Report_summary_${moment().format('MM/DD/YYYY')}.pdf`,
                'Report download successfully'
            )
    }

    const exportSummaryCsv = (exportData: any) => {
        return {
            data: exportData,
            headers: headersExportCsv,
            filename: 'Report_Summary.csv',
        }
    }

    return {
        getAllTask,
        getTasksByRangeDate,
        getDataChart,
        getDataProgressBar,
        getGroupedDatatable,
        transformDataToExport,
        exportSummaryPdf,
        exportSummaryCsv,
        progressCircleInit,
        groupByItems,
    }
}

export default ReportService
